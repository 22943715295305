import React, { useRef, useEffect, useState } from 'react';
import './ecom.css';

const currencySymbol = {
    RUB: "₽",
    AZN: "₼",
}

export default function Ecom({ cardInfo, setCardInfo, setActiveStep }) {
    const frontRef = useRef(null);
    const backRef = useRef(null);
    const ecomRef = useRef(null);
    const [ecomHeight, setEcomHeight] = useState(0);
    const [backHeight, setBackHeight] = useState(0);
    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState(null);
    const [cardHolder, setCardHolder] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCVV] = useState('');
    const [isCardNumberValid, setIsCardNumberValid] = useState(true);
    const [isExpiryDateValid, setIsExpiryDateValid] = useState(true);
    const [isCVVValid, setIsCVVValid] = useState(true);

    const updateBackHeight = () => {
        if (frontRef.current && backRef.current && ecomRef.current) {
            const frontHeight = frontRef.current.offsetHeight;
            const ecomHeight = frontHeight + 30;
            setEcomHeight(ecomHeight);
            setBackHeight(frontHeight);
        }
    };

    useEffect(() => {
        updateBackHeight();

        window.addEventListener('resize', updateBackHeight);

        return () => {
            window.removeEventListener('resize', updateBackHeight);
        };
    }, []);

    const formatCardNumber = (value) => {
        const cleaned = value.replace(/\D/g, '');

        if (/^4/.test(cleaned)) {
            setCardType('visa');
        } else if (/^5[1-5]/.test(cleaned)) {
            setCardType('mc');
        } else {
            setCardType(null);
        }

        const formatted = cleaned.replace(/(\d{4})(?=\d)/g, '$1 ');
        return formatted;
    };

    const validateCardNumber = (cardNumber) => {
        const cleaned = cardNumber.replace(/\D/g, '');
        if (cleaned.length !== 16) {
            return false;
        }

        const digits = cleaned.split('').map(Number);

        let sum = 0;
        for (let i = 0; i < digits.length; i++) {
            let digit = digits[digits.length - 1 - i];
            if (i % 2 === 1) {
                digit *= 2;
                if (digit > 9) {
                    digit = digit - 9;
                }
            }
            sum += digit;
        }

        return sum % 10 === 0;
    };

    const formatExpiryDate = (value) => {
        const cleaned = value.replace(/\D/g, '');

        let formatted = cleaned;
        if (cleaned.length > 2) {
            formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
        }

        return formatted;
    };

    const validateExpiryDate = (value) => {
        const [month, year] = value.split('/');
        const monthValid = /^(0[1-9]|1[0-2])$/.test(month);
        const currentYear = new Date().getFullYear() % 100;
        const yearValid = /^\d{2}$/.test(year) && parseInt(year) >= currentYear;

        return monthValid && yearValid;
    };

    const formatCardHolder = (value) => {
        const cleaned = value.replace(/[^A-Za-z\s]/g, '');
        return cleaned.toUpperCase();
    };

    const handleInputChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        setCardNumber(formattedValue);

        if (formattedValue.length === 19) {
            setIsCardNumberValid(validateCardNumber(formattedValue));
        } else {
            setIsCardNumberValid(true);
        }
    };

    const handleCardHolderChange = (e) => {
        const formattedValue = formatCardHolder(e.target.value);
        setCardHolder(formattedValue);
    };

    const handleExpiryDateChange = (e) => {
        const formattedValue = formatExpiryDate(e.target.value);
        setExpiryDate(formattedValue);

        if (formattedValue.length === 5) {
            setIsExpiryDateValid(validateExpiryDate(formattedValue));
        } else {
            setIsExpiryDateValid(true);
        }
    };


    const handleCVVChange = (e) => {
        const cleanedValue = e.target.value.replace(/\D/g, '');
        const truncatedValue = cleanedValue.slice(0, 3);
        setCVV(truncatedValue);
        setIsCVVValid(truncatedValue.length === 3);
    };


    const handlePay = async () => {
        if (!isCardNumberValid && !isExpiryDateValid && !isCVVValid) {
            return;
        };

        const body = {
            payment_id: cardInfo.id,
            card_number: cardNumber,
            card_holder: cardHolder,
            expiry_date: expiryDate,
            cvv: cvv,
        }

        const response = await fetch('/api/pay/ecom/' + cardInfo.id, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        if (response.ok) {
            const data = await response.json()
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: data.status,
                update: data.update,
            }))
            if (data.status === "CR") {
                setActiveStep(1);
            } else if (data.status === "OP") {
                setActiveStep(2);
            } else if (data.status === "NP") {
                setActiveStep(4);
            } else if (data.status === "CF") {
                setActiveStep(5);
            } else if (data.status === "CL") {
                setActiveStep(6);
            } else if (data.status === "AC") {
                setActiveStep(3);
            }
        }
        

        console.log(body);
    }



    return (
        <div className='ecom' ref={ecomRef} style={{ height: ecomHeight }}>
            <div className="card" style={{ height: ecomHeight }}>
                <div className="front" ref={frontRef}>
                    <div className="header">
                        <div className="title">Введите данные карты</div>
                        <div className="logo">
                            {cardType === 'visa' && <VISA />}
                            {cardType === 'mc' && <MC />}
                            {cardType === null && (
                                <>
                                    <MC />
                                    <VISA />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="content">
                        <div className="column">
                            <label>Номер карты</label>
                            <input
                                className={isCardNumberValid ? '' : 'invalid'}
                                id='cardNumber'
                                type="text"
                                placeholder='•••• •••• •••• ••••'
                                value={cardNumber}
                                onChange={handleInputChange}
                                maxLength={19}
                            />
                        </div>

                        <div className="row">
                            <div className="rowBlock large">
                                <label>Имя владельца</label>
                                <input
                                    type="text"
                                    id='cardHolder'
                                    placeholder='•••••• ••••••••'
                                    value={cardHolder}
                                    onChange={handleCardHolderChange}
                                />
                            </div>
                            <div className="rowBlock small">
                                <label>Срок действия</label>
                                <input
                                    className={isExpiryDateValid ? '' : 'invalid'}
                                    id='expiryDate'
                                    type="text"
                                    placeholder='••/••'
                                    value={expiryDate}
                                    onChange={handleExpiryDateChange}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="back"
                    ref={backRef}
                    style={{ height: backHeight }}
                >
                    <div className="stripe"></div>
                    <div className="cvvBlock">
                        <div className="cvv">
                            <label>CVV</label>
                            <input
                                className={isCVVValid ? '' : 'invalid'}
                                id='cvv'
                                type="text"
                                maxLength="3"
                                placeholder='•••'
                                value={cvv}
                                onChange={handleCVVChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button className='ready Ecom' onClick={handlePay}>
                Оплатить {cardInfo.amount.toLocaleString('ru-RU')} {currencySymbol[cardInfo.currency]}
            </button>
        </div>
    );
}


function MC() {
    return (
        <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7137 1.42853H9.28516V13.5714H15.7137V1.42853Z" fill="#FF5F00" />
            <path d="M9.27628 7.50106C9.27507 6.36507 9.53254 5.2437 10.0292 4.22186C10.5258 3.20003 11.2487 2.30451 12.1429 1.60313C11.0357 0.733593 9.70599 0.192877 8.3058 0.0427856C6.90561 -0.107306 5.49141 0.139281 4.22483 0.754364C2.95824 1.36945 1.89038 2.32821 1.14328 3.52108C0.396189 4.71394 0 6.09279 0 7.50002C0 8.90725 0.396189 10.2861 1.14328 11.479C1.89038 12.6718 2.95824 13.6306 4.22483 14.2457C5.49141 14.8608 6.90561 15.1073 8.3058 14.9573C9.70599 14.8072 11.0357 14.2664 12.1429 13.3969C11.2489 12.6957 10.5263 11.8006 10.0296 10.7791C9.53301 9.75766 9.27539 8.6367 9.27628 7.50106Z" fill="#EB001B" />
            <path d="M24.9998 7.50106C24.9997 8.90823 24.5802 10.287 23.7891 11.4798C22.9979 12.6725 21.8672 13.6312 20.5261 14.2461C19.1849 14.861 17.6875 15.1074 16.205 14.9571C14.7225 14.8069 13.3147 14.2659 12.1426 13.3962C13.0887 12.6945 13.8535 11.7992 14.3795 10.7778C14.9054 9.75641 15.1787 8.63563 15.1787 7.50002C15.1787 6.36441 14.9054 5.24362 14.3795 4.22223C13.8535 3.20084 13.0887 2.30552 12.1426 1.60381C13.3147 0.734103 14.7225 0.193188 16.205 0.0428976C17.6875 -0.107392 19.1849 0.139007 20.5261 0.753932C21.8672 1.36886 22.9979 2.32749 23.7891 3.52026C24.5802 4.71303 24.9997 6.09181 24.9998 7.49898V7.50106Z" fill="#F79E1B" />
            <path d="M24.4397 12.4345V12.1928H24.5405V12.1429H24.2852V12.1928H24.3946V12.4345H24.4397ZM24.9354 12.4345V12.1429H24.8581L24.768 12.3512L24.6779 12.1429H24.6092V12.4345H24.665V12.2158L24.7487 12.4053H24.8066L24.8903 12.2158V12.4366L24.9354 12.4345Z" fill="#F79E1B" />
        </svg>
    );
}

function VISA() {
    return (
        <svg width="25" height="9" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8377 8.45221H8.79777L10.0727 0.552653H12.1129L10.8377 8.45221ZM7.08164 0.552653L5.13685 5.98603L4.90672 4.81601L4.90693 4.81643L4.22052 1.26392C4.22052 1.26392 4.13753 0.552653 3.25284 0.552653H0.0377263L0 0.686411C0 0.686411 0.983189 0.892648 2.13384 1.58933L3.90614 8.45242H6.03159L9.2771 0.552653H7.08164ZM23.1268 8.45221H24.9999L23.3668 0.552442H21.727C20.9697 0.552442 20.7853 1.14115 20.7853 1.14115L17.7429 8.45221H19.8694L20.2946 7.27881H22.8879L23.1268 8.45221ZM20.8821 5.65786L21.954 2.70166L22.557 5.65786H20.8821ZM17.9024 2.45231L18.1935 0.755931C18.1935 0.755931 17.2952 0.411499 16.3587 0.411499C15.3464 0.411499 12.9424 0.857571 12.9424 3.02665C12.9424 5.06747 15.7639 5.09283 15.7639 6.16479C15.7639 7.23676 13.2331 7.04468 12.3979 6.3687L12.0946 8.14243C12.0946 8.14243 13.0055 8.5885 14.3972 8.5885C15.7893 8.5885 17.8894 7.86181 17.8894 5.88396C17.8894 3.83005 15.0425 3.63881 15.0425 2.74582C15.0427 1.85262 17.0294 1.96736 17.9024 2.45231Z" fill="#2566AF" />
        </svg>

    );
}

function CardChip() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 24 24">
            <path
                fill="#F79E1B"
                stroke="#000000"
                stroke-width="1"
                d="M10 4h10c1.11 0 2 .89 2 2v2h-3.41L16 10.59v4l-2 2V20h-4v-3.41l-2-2V9.41l2-2zm8 7.41V14h4v-4h-2.59zM6.59 8L8 6.59V4H4c-1.11 0-2 .89-2 2v2zM6 14v-4H2v4zm2 3.41L6.59 16H2v2c0 1.11.89 2 2 2h4zM17.41 16L16 17.41V20h4c1.11 0 2-.89 2-2v-2z"
            />
        </svg>
    );
}

function CardWireless() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
            <path d="M16.79,23C16.37,22.83 16.07,22.45 16,22C15.95,21.74 16,21.56 16.4,20.84C17.9,18.14 18.67,15.09 18.63,12C18.67,9 17.94,6.07 16.5,3.44C16.29,3 16.1,2.58 15.94,2.13C16,1.75 16.19,1.4 16.5,1.19C16.95,0.95 17.5,1 17.91,1.28C18.19,1.64 18.43,2 18.63,2.42C19.71,4.5 20.44,6.7 20.8,9C21.03,10.81 21.06,12.65 20.89,14.47C20.58,16.81 19.89,19.07 18.83,21.18C18.19,22.46 17.83,23 17.45,23C17.37,23 17.28,23 17.2,23C17.06,23 16.93,23 16.79,23V23M12.43,20.79C11.86,20.63 11.5,20.05 11.62,19.47C11.62,19.35 11.93,18.8 12.21,18.24C13.39,15.97 13.9,13.41 13.67,10.86C13.53,9.03 13,7.25 12.13,5.64C11.5,4.38 11.46,4.18 11.83,3.64C12.27,3.15 13,3.08 13.54,3.5C14.26,4.56 14.83,5.72 15.25,6.94C16.53,10.73 16.33,14.86 14.69,18.5C13.85,20.39 13.26,21 12.43,20.74V20.79M7.93,18.56C7.57,18.4 7.3,18.08 7.2,17.7C7.2,17.5 7.2,17.24 7.65,16.44C9.14,13.74 9.14,10.46 7.65,7.76C7,6.5 7,6.24 7.53,5.76C7.72,5.54 8,5.43 8.3,5.47C8.94,5.47 9.3,5.78 9.84,6.91C10.69,8.47 11.13,10.22 11.12,12C11.16,13.81 10.72,15.61 9.85,17.2C9.31,18.25 9.04,18.5 8.5,18.59C8.31,18.61 8.11,18.59 7.93,18.5V18.56M3.72,16.43C3.39,16.27 3.13,16 3,15.65C2.9,15.3 3,15 3.4,14.36C3.9,13.68 4.14,12.84 4.09,12C4.16,11.15 3.93,10.31 3.44,9.61C3.27,9.36 3.13,9.09 3,8.82C2.89,8.19 3.31,7.59 4,7.47C4.54,7.37 4.92,7.6 5.42,8.36C6.87,10.57 6.87,13.42 5.42,15.63C4.91,16.4 4.33,16.63 3.73,16.43H3.72Z" />
        </svg>
    )
}