import './App.css';
import React, { useState, useEffect } from 'react';
import Payment from '../../pages/Payment/Payment';
import Waiting from '../Waiting/Waiting';
import End from '../End/End';
import Chat from '../../pages/Chat/Chat';
import Loading from '../Loading/Loading';
import Code from '../Code/Code';
import { Routes, Route, useParams } from 'react-router-dom';
import ChoosePage from '../../pages/Choose/Choose';

const METRIKA_ID = 99785423;

function PaymentPage() {
  const { id } = useParams();
  const mobile = window.matchMedia('(max-width: 1000px)').matches;
  const [captcha, setCaptcha] = useState(true);
  const [cardInfo, setCardInfo] = useState({})
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isStatusFetching, setIsStatusFetching] = useState(false);

  useEffect(() => {
    (function (m, e, t, r, i, k, a) {
      m[i] = m[i] || function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
      m[i].l = 1 * new Date();
      k = e.createElement(t);
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    if (window.ym) {
      window.ym(METRIKA_ID, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
        defer: true
      });
      window.ym(METRIKA_ID, "userParams", {
        gdpr: true
      });
    } else {
      console.warn("Ошибка: Яндекс.Метрика не загрузилась");
    }
  }, []);

  useEffect(() => {
    const fetchAuth = async () => {
      const ip = await getIPAddress();
      await fetch('api/pay/auth/' + id + "?ip=" + ip);
      fetchDetail();
    };

    const fetchDetail = async () => {
      const response = await fetch('api/pay/detail/' + id);
      if (response.ok) {
        const data = await response.json();
        setCardInfo(data);
        switch (data.status) {
          case 'CR': setActiveStep(1); break;
          case 'OP': setActiveStep(2); break;
          case 'NP': setActiveStep(4); break;
          case 'CF': setActiveStep(5); break;
          case 'CL': setActiveStep(6); break;
          case 'SF': setActiveStep(7); break;
          case 'AC': setActiveStep(3); break;
          case 'CD': setActiveStep(8); break;
          default: break;
        }

        setLoading(false);

        if (data.status === 'CR' && !isStatusFetching) {
          setIsStatusFetching(true);
          fetchPaymentStatusPeriodically();
        }
      }
    };

    const fetchPaymentStatusPeriodically = async () => {
      const interval = setInterval(async () => {
        const response = await fetch('api/pay/detail/' + id);
        if (response.ok) {
          const data = await response.json();
          setCardInfo(data);
          switch (data.status) {
            case 'CR': setActiveStep(1); break;
            case 'OP': setActiveStep(2); break;
            case 'NP': setActiveStep(4); break;
            case 'CF': setActiveStep(5); break;
            case 'CL': setActiveStep(6); break;
            case 'SF': setActiveStep(7); break;
            case 'AC': setActiveStep(3); break;
            case 'CD': setActiveStep(8); break;
            default: break;
          }

          if (data.status !== 'CR') {
            clearInterval(interval);
            setIsStatusFetching(false);
          }
        }
      }, 10000);
    };

    fetchAuth();
  }, [id, isStatusFetching]);

  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Ошибка при получении IP-адреса:', error);
      return null;
    }
  };

  return (
    <main>
      {/* <Header /> */}
      {loading && captcha && (
        <Loading />
      )}
      {/* {captcha && !mobile && !loading && (
        <PaySteps
          activeStep={activeStep}
        />
      )} */}
      {captcha && !loading && activeStep === 1 && (
        <Payment
          cardInfo={cardInfo}
          setActiveStep={setActiveStep}
          setCardInfo={setCardInfo}
        />
      )}
      {captcha && !loading && (activeStep === 2 || activeStep === 7) && (
        <Waiting
          cardInfo={cardInfo}
          setActiveStep={setActiveStep}
          setCardInfo={setCardInfo}
          sendFile={activeStep === 7} />
      )}
      {captcha && !loading && (activeStep === 3 || activeStep === 4 || activeStep === 6) ? (
        <End
          success={activeStep === 3}
          close={activeStep === 6}
          cardInfo={cardInfo}
          setCardInfo={setCardInfo}
          setActiveStep={setActiveStep}
        />
      ) : (
        <></>
      )}
      {captcha && !loading && activeStep === 5 && (
        <Chat
          cardInfo={cardInfo}
        />
      )}
      {captcha && !loading && activeStep === 8 && (
        <Code
          cardInfo={cardInfo}
          setActiveStep={setActiveStep}
          setCardInfo={setCardInfo}
        />
      )}
    </main>
  );
}



export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ChoosePage />} />
      <Route path="/:id" element={<PaymentPage />} />
    </Routes>
  );
}


