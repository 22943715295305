

export default function Amra() {

    return (
        <svg width="36" height="36" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-5a2dcf33="">
            <path d="M25.366 0C4.35212 0 0 4.35212 0 25.366C0 46.3799 4.35212 50.732 25.366 50.732C46.3799 50.732 50.732 46.3799 50.732 25.366C50.732 4.35212 46.3799 0 25.366 0Z" fill="#FFDC00" />
            <path d="M31.3575 17.3664C31.1289 17.3664 30.903 17.375 30.6773 17.388C29.0333 12.7237 24.592 9.38086 19.3663 9.38086C12.7439 9.38086 7.375 14.7498 7.375 21.3721C7.375 27.9943 12.7439 33.3634 19.3663 33.3634C19.5949 33.3634 19.8221 33.3576 20.0478 33.3446C21.6903 38.0074 26.1332 41.3502 31.3575 41.3502C37.9812 41.3502 43.3503 35.9813 43.3503 29.3576C43.3503 22.7353 37.9812 17.3664 31.3575 17.3664ZM19.3677 17.3649L19.3663 29.359C19.3663 29.673 19.3821 29.9842 19.4067 30.2924C19.3923 30.2924 19.3793 30.2939 19.3663 30.2939C14.4386 30.2939 10.4445 26.2997 10.4445 21.3721C10.4445 16.4445 14.4386 12.4503 19.3663 12.4503C23.0869 12.4503 26.275 14.7296 27.6136 17.9669C25.5991 18.6282 23.8206 19.8062 22.4357 21.3475L22.4372 14.9091C20.7453 14.9091 19.3735 16.0088 19.3677 17.3649ZM22.4458 29.7483C22.44 29.6195 22.4357 29.4892 22.4357 29.359C22.4357 25.5139 24.8684 22.2361 28.278 20.9828C28.2838 21.1116 28.2881 21.2419 28.2881 21.3721C28.2881 25.2172 25.8554 28.4935 22.4458 29.7483ZM31.3575 38.2808C27.6368 38.2808 24.4501 36.0029 23.1115 32.7657C25.1245 32.1044 26.9031 30.9234 28.2881 29.3822V35.8221C29.9826 35.8221 31.3575 34.7193 31.3575 33.3576V21.3721C31.3575 21.0566 31.3415 20.7469 31.3184 20.4372H31.3575C36.2851 20.4372 40.2808 24.4314 40.2808 29.359C40.2808 34.2866 36.2851 38.2808 31.3575 38.2808ZM34.9654 9.38086H32.4993C32.4993 11.0754 33.6035 12.4503 34.9654 12.4503C37.9001 12.4503 40.2793 14.8309 40.2808 17.7658C40.2808 19.1275 41.6541 20.2318 43.3503 20.2318V17.7658C43.3503 13.1348 39.5963 9.38086 34.9654 9.38086ZM15.7599 38.2808C12.825 38.2808 10.4445 35.9002 10.4445 32.9653C10.4445 31.6036 9.06956 30.4995 7.375 30.4995V32.9653C7.375 37.5963 11.129 41.3502 15.7599 41.3502H18.2259C18.2259 39.6542 17.1216 38.2808 15.7599 38.2808Z" fill="#1D1E1C" />
        </svg>
    )
}