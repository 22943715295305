import React from 'react';

export default function Alif() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M20.1429 9.58907H15.3484L9.07858 24.8578H13.1355L17.7825 13.0559L22.3558 24.8578H26.4127L20.1429 9.58907Z" fill="#00AF66" />
                <path d="M28.1829 28.6935C27.0027 29.5786 25.675 30.2425 24.1998 30.8326C16.5285 33.8568 7.97212 30.685 5.02163 23.4563C2.07115 16.2277 5.75925 7.74502 13.4305 4.72077C14.9795 4.13067 16.5285 3.76186 18.0775 3.61434L16.6023 0C14.9057 0.147524 13.2092 0.590083 11.5127 1.25394C2.07114 5.01581 -2.42835 15.7113 1.40728 24.9316C5.16915 34.1518 15.8647 38.3563 25.2324 34.6682C26.7815 34.0043 28.3305 33.2667 29.5844 32.3816L28.1829 28.6935Z" fill="#00AF66" />
            </g>
        </svg>
    );
}