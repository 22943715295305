import './sbp.css';
import { useState, useEffect } from 'react';
import SliderNumber from '../Sliders/TBank/SliderNumber';
import SliderCard from '../Sliders/TBank/SliderCard';
import Copy from '../Copy/Copy';

const METRIKA_ID = 99785423;

export default function SBP({ cardInfo, setShowSBP, onlyReq }) {

    const [copy, setCopy] = useState(false);
    const [copyText, setCopyText] = useState("");


    const handleCopy = (sum) => {
        if (window.ym) {
            window.ym(METRIKA_ID, "reachGoal", "click_copy_req");
        } else {
            console.warn("Яндекс.Метрика ещё не загружена");
        }
        let copyInfo = cardInfo.card_info;
        if (sum) {
            copyInfo = cardInfo.amount;
            setCopyText("Сумма для перевода скопирована");
        } else {
            copyInfo = cardInfo.card_info;
            setCopyText("Реквизит для перевода скопирован");
        }
        if (navigator.clipboard) {
            navigator.clipboard.writeText(copyInfo).then(() => {
                setCopy(true);

            }).catch(() => {
                fallbackCopyTextToClipboard(copyInfo);
            });
        } else {
            fallbackCopyTextToClipboard(copyInfo);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setCopy(true);
        } catch (err) {
            console.error('Не удалось скопировать текст', err);
        }

        document.body.removeChild(textArea);
    }

    return (
        <div className="payContainer">
            {!onlyReq && (
                <>
                    <div className="topVertical">
                        <div className="payContainerTop" onClick={() => setShowSBP(false)}>
                            <div className="payForSPB" >
                                <h2>Оплата через СПБ</h2>
                                <div className="SBPico">
                                    <SBPico />
                                </div>
                            </div>
                            <button
                                className="backButton"
                            >
                                <Row />
                            </button>
                        </div>
                        {cardInfo.card_info === cardInfo.tpay ? (
                            <div className="list">
                                <p>1. В разделе «Платежи» выберите «Перевод по телефону»</p>
                                <p>2. Нажмите на телефонный код страны</p>
                                <p>3. Выберите Таджикистан (+992)</p>
                                <p>4. Введите номер указанный в реквизитах получателя</p>
                                <p>5. Выберите банк {cardInfo.bank_full}</p>
                                <p>6. Переведите сумму вашего платежа</p>
                            </div>
                        ) : (
                            <div className="list">
                                <p>1. В разделе «Платежи» выберите «Перевод по номеру карты»</p>
                                <p>2. Введите номер карты, указанный в реквизитах получателя</p>
                                <p>3. Переведите сумму вашего платежа</p>
                            </div>
                        )}
                    </div>
                    {cardInfo.card_info === cardInfo.tpay ? (
                        <SliderNumber bank={cardInfo.bank} bankFull={cardInfo.bank_full} />
                    ) : (
                        <SliderCard />
                    )}
                    <div className="requisite">
                        {cardInfo.card_info === cardInfo.tpay ? (
                            <div className="phoneNumber">
                                <div className="leftBlock">
                                    <div className="ico">
                                        <Phone />
                                    </div>
                                    <div className="requisiteInfo">
                                        <span className="title">Номер телефона</span>
                                        <p className="text">{cardInfo.card_info}</p>
                                    </div>
                                </div>
                                <div className="copy" onClick={() => { handleCopy(false) }}>
                                    <CopyIcon />
                                </div>
                            </div>
                        ) : (
                            <div className="phoneNumber">
                                <div className="leftBlock">
                                    <div className="ico">
                                        <Card />
                                    </div>
                                    <div className="requisiteInfo">
                                        <span className="title">Номер карты</span>
                                        <p className="text">{cardInfo.card_info}</p>
                                    </div>
                                </div>
                                <div className="copy" onClick={() => { handleCopy(false) }}>
                                    <CopyIcon />
                                </div>
                            </div>
                        )}
                        <div className="bankName">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Bank />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Банк получателя</span>
                                    <p className="text">{cardInfo.bank_full}</p>
                                </div>
                            </div>
                        </div>
                        <div className="amountPay">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Amount />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Сумма перевода</span>
                                    <p className="text">{cardInfo.amount.toLocaleString('ru-RU')} ₽</p>
                                </div>
                            </div>
                            <div className="copy" onClick={() => { handleCopy(true) }}>
                                <CopyIcon />
                            </div>
                        </div>
                        <div className="recieverName">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Reciever />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Получатель</span>
                                    <p className="text">{cardInfo.full_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {onlyReq && (
                <div className="requisite">
                    {cardInfo.bank === "m10" && (
                        <div className="bankName">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Az />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Страна</span>
                                    <p className="text">Азербайджан</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {cardInfo.card_info === cardInfo.tpay ? (
                        <div className="phoneNumber">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Phone />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Номер телефона</span>
                                    <p className="text">{cardInfo.card_info}</p>
                                </div>
                            </div>
                            <div className="copy" onClick={() => { handleCopy(false) }}>
                                <CopyIcon />
                            </div>
                        </div>
                    ) : (
                        <div className="phoneNumber">
                            <div className="leftBlock">
                                <div className="ico">
                                    <Card />
                                </div>
                                <div className="requisiteInfo">
                                    <span className="title">Номер карты</span>
                                    <p className="text">{cardInfo.card_info}</p>
                                </div>
                            </div>
                            <div className="copy" onClick={() => { handleCopy(false) }}>
                                <CopyIcon />
                            </div>
                        </div>
                    )}
                    <div className="bankName">
                        <div className="leftBlock">
                            <div className="ico">
                                <Bank />
                            </div>
                            <div className="requisiteInfo">
                                <span className="title">Банк получателя</span>
                                <p className="text">{cardInfo.bank_full}</p>
                            </div>
                        </div>
                    </div>
                    <div className="amountPay">
                        <div className="leftBlock">
                            <div className="ico">
                                <Amount />
                            </div>
                            <div className="requisiteInfo">
                                <span className="title">Сумма перевода</span>
                                <p className="text">{cardInfo.amount.toLocaleString('ru-RU')} ₽</p>
                            </div>
                        </div>
                        <div className="copy" onClick={() => { handleCopy(true) }}>
                            <CopyIcon />
                        </div>
                    </div>
                    <div className="recieverName">
                        <div className="leftBlock">
                            <div className="ico">
                                <Reciever />
                            </div>
                            <div className="requisiteInfo">
                                <span className="title">Получатель</span>
                                <p className="text">{cardInfo.full_name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Copy copy={copy} onHide={() => setCopy(false)} text={copyText} />
        </div>
    );
}

function Az() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 512 512">
            <mask id="circleFlagsAz0">
                <circle cx={256} cy={256} r={256} fill="#fff"></circle>
            </mask>
            <g mask="url(#circleFlagsAz0)">
                <path fill="#d80027" d="m0 166.9l253-31.8l259 31.8v178l-257.5 37.4L0 345z"></path>
                <path fill="#338af3" d="M0 0h512v166.9H0z"></path>
                <path fill="#6da544" d="M0 344.9h512V512H0z"></path>
                <g fill="#fff">
                    <path d="M261.6 328.2a72.3 72.3 0 1 1 34.4-136a89 89 0 1 0 0 127.3a72 72 0 0 1-34.4 8.7"></path>
                    <path d="m317.2 206l9.6 26.8l25.8-12.3l-12.2 25.8l26.9 9.6l-27 9.6l12.3 25.8l-25.8-12.3l-9.6 27l-9.6-27l-25.8 12.3l12.3-25.8l-27-9.6l27-9.6l-12.3-25.8l25.8 12.3z"></path>
                </g>
            </g>
        </svg>
    );
}

function SBPico() {
    return (
        <svg width="12" height="12" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_43_2094)">
                <path d="M0 8.27148L4.54124 16.4969V21.5142L0.0053125 29.7234L0 8.27148Z" fill="#5B57A2" />
                <path d="M17.4364 13.5036L21.6918 10.8607L30.4005 10.8525L17.4364 18.9003V13.5036Z" fill="#D90751" />
                <path d="M17.4126 8.2229L17.4366 19.113L12.8848 16.2789V0L17.4126 8.2229Z" fill="#FAB718" />
                <path d="M30.4006 10.8525L21.6916 10.8607L17.4126 8.2229L12.8848 0L30.4006 10.8525Z" fill="#ED6F26" />
                <path d="M17.4366 29.769V24.4854L12.8848 21.7051L12.8872 38.0001L17.4366 29.769Z" fill="#63B22F" />
                <path d="M21.6812 27.1502L4.54094 16.4969L0 8.27148L30.3821 27.1394L21.6812 27.1502Z" fill="#1487C9" />
                <path d="M12.8877 38.0004L17.4364 29.7692L21.6811 27.1504L30.382 27.1396L12.8877 38.0004Z" fill="#017F36" />
                <path d="M0.00561523 29.7228L12.9222 21.7048L8.57967 19.0049L4.54154 21.5136L0.00561523 29.7228Z" fill="#984995" />
            </g>
            <defs>
                <clipPath id="clip0_43_2094">
                    <rect width="75" height="38" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

function Row() {
    return (
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.72975 4.52401L5.35161 8.14587C5.54556 8.33982 5.54556 8.65427 5.35161 8.84821C5.15767 9.04216 4.84322 9.04216 4.64927 8.84821L0.746474 4.94542C0.513742 4.71268 0.513738 4.33535 0.746474 4.10261L4.64927 0.199811C4.84322 0.00586542 5.15767 0.00586541 5.35161 0.199811C5.54556 0.393757 5.54556 0.708205 5.35161 0.90215L1.72975 4.52401Z" fill="#7A8699" />
        </svg>

    );
}

function CopyIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.625 9.8625H9.9975C8.6625 9.8625 7.575 8.7825 7.575 7.44V5.8125C7.575 5.505 7.3275 5.25 7.0125 5.25H4.635C2.9025 5.25 1.5 6.375 1.5 8.385V13.365C1.5 15.375 2.9025 16.5 4.635 16.5H9.0525C10.785 16.5 12.1875 15.375 12.1875 13.365V10.425C12.1875 10.11 11.9325 9.8625 11.625 9.8625Z" fill="url(#paint0_linear_26_198)" />
            <path d="M13.3653 1.5H11.8878H11.0703H8.94781C7.25281 1.5 5.88031 2.58 5.82031 4.5075C5.86531 4.5075 5.90281 4.5 5.94781 4.5H8.07031H8.88781H10.3653C12.0978 4.5 13.5003 5.625 13.5003 7.635V9.1125V11.145V12.6225C13.5003 12.6675 13.4928 12.705 13.4928 12.7425C15.1653 12.69 16.5003 11.58 16.5003 9.6225V8.145V6.1125V4.635C16.5003 2.625 15.0978 1.5 13.3653 1.5Z" fill="url(#paint1_linear_26_198)" />
            <path d="M8.98547 5.3625C8.75297 5.13 8.35547 5.2875 8.35547 5.61V7.575C8.35547 8.4 9.05297 9.075 9.90797 9.075C10.4405 9.0825 11.183 9.0825 11.8205 9.0825C12.143 9.0825 12.308 8.7075 12.083 8.4825C11.2655 7.665 9.81047 6.2025 8.98547 5.3625Z" fill="url(#paint2_linear_26_198)" />
            <defs>
                <linearGradient id="paint0_linear_26_198" x1="11.2816" y1="5.76089" x2="1.88882" y2="15.5318" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint1_linear_26_198" x1="15.5951" y1="2.01055" x2="6.20852" y2="11.7747" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint2_linear_26_198" x1="11.862" y1="5.42562" x2="8.67429" y2="8.91666" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

function Card() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.415 4.9125H4.335C4.125 4.9125 3.93 4.92 3.75 4.9275C1.9725 5.0325 1.5 5.685 1.5 7.71V8.145C1.5 8.5575 1.8375 8.895 2.25 8.895H13.5C13.9125 8.895 14.25 8.5575 14.25 8.145V7.71C14.25 5.475 13.68 4.9125 11.415 4.9125Z" fill="white" />
            <path d="M11.415 4.9125H4.335C4.125 4.9125 3.93 4.92 3.75 4.9275C1.9725 5.0325 1.5 5.685 1.5 7.71V8.145C1.5 8.5575 1.8375 8.895 2.25 8.895H13.5C13.9125 8.895 14.25 8.5575 14.25 8.145V7.71C14.25 5.475 13.68 4.9125 11.415 4.9125Z" fill="url(#paint0_linear_126_2215)" />
            <path d="M2.25 10.02C1.8375 10.02 1.5 10.3575 1.5 10.77V12.9525C1.5 15.1875 2.07 15.75 4.335 15.75H11.415C13.6425 15.75 14.2275 15.21 14.25 13.0725V10.77C14.25 10.3575 13.9125 10.02 13.5 10.02H2.25ZM5.22 13.92H3.9375C3.63 13.92 3.375 13.665 3.375 13.3575C3.375 13.05 3.63 12.795 3.9375 12.795H5.2275C5.535 12.795 5.79 13.05 5.79 13.3575C5.79 13.665 5.535 13.92 5.22 13.92ZM9.4125 13.92H6.8325C6.525 13.92 6.27 13.665 6.27 13.3575C6.27 13.05 6.525 12.795 6.8325 12.795H9.4125C9.72 12.795 9.975 13.05 9.975 13.3575C9.975 13.665 9.7275 13.92 9.4125 13.92Z" fill="white" />
            <path d="M2.25 10.02C1.8375 10.02 1.5 10.3575 1.5 10.77V12.9525C1.5 15.1875 2.07 15.75 4.335 15.75H11.415C13.6425 15.75 14.2275 15.21 14.25 13.0725V10.77C14.25 10.3575 13.9125 10.02 13.5 10.02H2.25ZM5.22 13.92H3.9375C3.63 13.92 3.375 13.665 3.375 13.3575C3.375 13.05 3.63 12.795 3.9375 12.795H5.2275C5.535 12.795 5.79 13.05 5.79 13.3575C5.79 13.665 5.535 13.92 5.22 13.92ZM9.4125 13.92H6.8325C6.525 13.92 6.27 13.665 6.27 13.3575C6.27 13.05 6.525 12.795 6.8325 12.795H9.4125C9.72 12.795 9.975 13.05 9.975 13.3575C9.975 13.665 9.7275 13.92 9.4125 13.92Z" fill="url(#paint1_linear_126_2215)" />
            <path d="M16.5002 9.9975V6.0675C16.5002 3.72 15.1577 2.7 13.1327 2.7H6.4352C5.8652 2.7 5.3552 2.7825 4.9052 2.955C4.5527 3.0825 4.2377 3.27 3.9827 3.5175C3.8477 3.645 3.9527 3.855 4.1477 3.855H12.3002C13.9877 3.855 15.3527 5.22 15.3527 6.9075V12.285C15.3527 12.4725 15.5552 12.5775 15.6902 12.4425C16.2077 11.895 16.5002 11.0925 16.5002 9.9975Z" fill="white" />
            <path d="M16.5002 9.9975V6.0675C16.5002 3.72 15.1577 2.7 13.1327 2.7H6.4352C5.8652 2.7 5.3552 2.7825 4.9052 2.955C4.5527 3.0825 4.2377 3.27 3.9827 3.5175C3.8477 3.645 3.9527 3.855 4.1477 3.855H12.3002C13.9877 3.855 15.3527 5.22 15.3527 6.9075V12.285C15.3527 12.4725 15.5552 12.5775 15.6902 12.4425C16.2077 11.895 16.5002 11.0925 16.5002 9.9975Z" fill="url(#paint2_linear_126_2215)" />
            <defs>
                <linearGradient id="paint0_linear_126_2215" x1="7.875" y1="4.9125" x2="7.875" y2="8.895" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint1_linear_126_2215" x1="7.875" y1="10.02" x2="7.875" y2="15.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint2_linear_126_2215" x1="10.211" y1="2.7" x2="10.211" y2="12.5036" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>

    )
}

function Phone() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.2875 11.2125L6.9 12.6C6.6075 12.8925 6.1425 12.8925 5.8425 12.6075C5.76 12.525 5.6775 12.45 5.595 12.3675C4.8225 11.5875 4.125 10.77 3.5025 9.915C2.8875 9.06 2.3925 8.205 2.0325 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.1225 9.99 8.205 10.065 8.28 10.14C8.58 10.4325 8.5875 10.9125 8.2875 11.2125Z" fill="url(#paint0_linear_26_191)" />
            <path d="M16.4777 13.7475C16.4777 13.9575 16.4402 14.175 16.3652 14.385C16.3427 14.445 16.3202 14.505 16.2902 14.565C16.1627 14.835 15.9977 15.09 15.7802 15.33C15.4127 15.735 15.0077 16.0275 14.5502 16.215C14.5427 16.215 14.5352 16.2225 14.5277 16.2225C14.0852 16.4025 13.6052 16.5 13.0877 16.5C12.3227 16.5 11.5052 16.32 10.6427 15.9525C9.78019 15.585 8.9177 15.09 8.0627 14.4675C7.7702 14.25 7.4777 14.0325 7.2002 13.8L9.65269 11.3475C9.86269 11.505 10.0502 11.625 10.2077 11.7075C10.2452 11.7225 10.2902 11.745 10.3427 11.7675C10.4027 11.79 10.4627 11.7975 10.5302 11.7975C10.6577 11.7975 10.7552 11.7525 10.8377 11.67L11.4077 11.1075C11.5952 10.92 11.7752 10.7775 11.9477 10.6875C12.1202 10.5825 12.2927 10.53 12.4802 10.53C12.6227 10.53 12.7727 10.56 12.9377 10.6275C13.1027 10.695 13.2752 10.7925 13.4627 10.92L15.9452 12.6825C16.1402 12.8175 16.2752 12.975 16.3577 13.1625C16.4327 13.35 16.4777 13.5375 16.4777 13.7475Z" fill="url(#paint1_linear_26_191)" />
            <defs>
                <linearGradient id="paint0_linear_26_191" x1="5.00439" y1="1.5" x2="5.00439" y2="12.8203" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint1_linear_26_191" x1="11.8389" y1="10.53" x2="11.8389" y2="16.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>

    );
}

function Bank() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 14.25V16.5H1.5V14.25C1.5 13.8375 1.8375 13.5 2.25 13.5H15.75C16.1625 13.5 16.5 13.8375 16.5 14.25Z" fill="url(#paint0_linear_26_208)" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.25 8.25H3.75V13.5H5.25V8.25Z" fill="url(#paint1_linear_26_208)" />
            <path d="M8.25 8.25H6.75V13.5H8.25V8.25Z" fill="url(#paint2_linear_26_208)" />
            <path d="M11.25 8.25H9.75V13.5H11.25V8.25Z" fill="url(#paint3_linear_26_208)" />
            <path d="M14.25 8.25H12.75V13.5H14.25V8.25Z" fill="url(#paint4_linear_26_208)" />
            <path d="M17.25 17.0625H0.75C0.4425 17.0625 0.1875 16.8075 0.1875 16.5C0.1875 16.1925 0.4425 15.9375 0.75 15.9375H17.25C17.5575 15.9375 17.8125 16.1925 17.8125 16.5C17.8125 16.8075 17.5575 17.0625 17.25 17.0625Z" fill="url(#paint5_linear_26_208)" />
            <path d="M16.0275 4.3125L9.2775 1.6125C9.1275 1.5525 8.8725 1.5525 8.7225 1.6125L1.9725 4.3125C1.71 4.4175 1.5 4.725 1.5 5.01V7.5C1.5 7.9125 1.8375 8.25 2.25 8.25H15.75C16.1625 8.25 16.5 7.9125 16.5 7.5V5.01C16.5 4.725 16.29 4.4175 16.0275 4.3125ZM9 6.375C8.3775 6.375 7.875 5.8725 7.875 5.25C7.875 4.6275 8.3775 4.125 9 4.125C9.6225 4.125 10.125 4.6275 10.125 5.25C10.125 5.8725 9.6225 6.375 9 6.375Z" fill="url(#paint6_linear_26_208)" />
            <defs>
                <linearGradient id="paint0_linear_26_208" x1="9" y1="13.5" x2="9" y2="16.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint1_linear_26_208" x1="4.5" y1="8.25" x2="4.5" y2="13.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint2_linear_26_208" x1="7.5" y1="8.25" x2="7.5" y2="13.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint3_linear_26_208" x1="10.5" y1="8.25" x2="10.5" y2="13.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint4_linear_26_208" x1="13.5" y1="8.25" x2="13.5" y2="13.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint5_linear_26_208" x1="9" y1="15.9375" x2="9" y2="17.0625" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint6_linear_26_208" x1="9" y1="1.5675" x2="9" y2="8.25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>
    );
}

function Amount() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5625 11.94H10.05C10.5375 11.94 10.9425 11.505 10.9425 10.98C10.9425 10.3275 10.71 10.2 10.3275 10.065L9.57 9.80251V11.94H9.5625Z" fill="url(#paint0_linear_26_224)" />
            <path d="M8.97755 1.42501C4.83755 1.44001 1.48505 4.80751 1.50005 8.94751C1.51505 13.0875 4.88255 16.44 9.02255 16.425C13.1625 16.41 16.515 13.0425 16.5 8.90251C16.4851 4.76251 13.1175 1.41751 8.97755 1.42501ZM10.6951 9.00001C11.2801 9.20251 12.0676 9.63751 12.0676 10.98C12.0676 12.135 11.16 13.065 10.05 13.065H9.56255V13.5C9.56255 13.8075 9.30755 14.0625 9.00005 14.0625C8.69255 14.0625 8.43755 13.8075 8.43755 13.5V13.065H8.16755C6.93755 13.065 5.94005 12.03 5.94005 10.755C5.94005 10.4475 6.19505 10.1925 6.50255 10.1925C6.81005 10.1925 7.06505 10.4475 7.06505 10.755C7.06505 11.4075 7.56005 11.94 8.16755 11.94H8.43755V9.40501L7.30505 9.00001C6.72005 8.79751 5.93255 8.36251 5.93255 7.02001C5.93255 5.86501 6.84005 4.93501 7.95005 4.93501H8.43755V4.50001C8.43755 4.19251 8.69255 3.93751 9.00005 3.93751C9.30755 3.93751 9.56255 4.19251 9.56255 4.50001V4.93501H9.83255C11.0625 4.93501 12.0601 5.97001 12.0601 7.24501C12.0601 7.55251 11.805 7.80751 11.4976 7.80751C11.1901 7.80751 10.9351 7.55251 10.9351 7.24501C10.9351 6.59251 10.4401 6.06001 9.83255 6.06001H9.56255V8.59501L10.6951 9.00001Z" fill="url(#paint1_linear_26_224)" />
            <path d="M7.06543 7.02749C7.06543 7.67999 7.29793 7.80749 7.68043 7.94249L8.43793 8.20499V6.05999H7.95043C7.46293 6.05999 7.06543 6.49499 7.06543 7.02749Z" fill="url(#paint2_linear_26_224)" />
            <defs>
                <linearGradient id="paint0_linear_26_224" x1="10.2525" y1="9.80251" x2="10.2525" y2="11.94" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint1_linear_26_224" x1="9.00005" y1="1.425" x2="9.00005" y2="16.4251" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint2_linear_26_224" x1="7.75168" y1="6.05999" x2="7.75168" y2="8.20499" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>
    );
}


function Reciever() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 9.00002C16.5 4.86752 13.1325 1.50002 9 1.50002C4.8675 1.50002 1.5 4.86752 1.5 9.00002C1.5 11.175 2.4375 13.1325 3.9225 14.505C3.9225 14.5125 3.9225 14.5125 3.915 14.52C3.99 14.595 4.08 14.655 4.155 14.7225C4.2 14.76 4.2375 14.7975 4.2825 14.8275C4.4175 14.94 4.5675 15.045 4.71 15.15C4.7625 15.1875 4.8075 15.2175 4.86 15.255C5.0025 15.3525 5.1525 15.4425 5.31 15.525C5.3625 15.555 5.4225 15.5925 5.475 15.6225C5.625 15.705 5.7825 15.78 5.9475 15.8475C6.0075 15.8775 6.0675 15.9075 6.1275 15.93C6.2925 15.9975 6.4575 16.0575 6.6225 16.11C6.6825 16.1325 6.7425 16.155 6.8025 16.17C6.9825 16.2225 7.1625 16.2675 7.3425 16.3125C7.395 16.3275 7.4475 16.3425 7.5075 16.35C7.7175 16.395 7.9275 16.425 8.145 16.4475C8.175 16.4475 8.205 16.455 8.235 16.4625C8.49 16.485 8.745 16.5 9 16.5C9.255 16.5 9.51 16.485 9.7575 16.4625C9.7875 16.4625 9.8175 16.455 9.8475 16.4475C10.065 16.425 10.275 16.395 10.485 16.35C10.5375 16.3425 10.59 16.32 10.65 16.3125C10.83 16.2675 11.0175 16.23 11.19 16.17C11.25 16.1475 11.31 16.125 11.37 16.11C11.535 16.05 11.7075 15.9975 11.865 15.93C11.925 15.9075 11.985 15.8775 12.045 15.8475C12.2025 15.78 12.36 15.705 12.5175 15.6225C12.5775 15.5925 12.63 15.555 12.6825 15.525C12.8325 15.435 12.9825 15.3525 13.1325 15.255C13.185 15.225 13.23 15.1875 13.2825 15.15C13.4325 15.045 13.575 14.94 13.71 14.8275C13.755 14.79 13.7925 14.7525 13.8375 14.7225C13.92 14.655 14.0025 14.5875 14.0775 14.52C14.0775 14.5125 14.0775 14.5125 14.07 14.505C15.5625 13.1325 16.5 11.175 16.5 9.00002ZM12.705 12.7275C10.6725 11.3625 7.3425 11.3625 5.295 12.7275C4.965 12.945 4.695 13.2 4.47 13.4775C3.33 12.3225 2.625 10.74 2.625 9.00002C2.625 5.48252 5.4825 2.62502 9 2.62502C12.5175 2.62502 15.375 5.48252 15.375 9.00002C15.375 10.74 14.67 12.3225 13.53 13.4775C13.3125 13.2 13.035 12.945 12.705 12.7275Z" fill="url(#paint0_linear_26_242)" />
            <path d="M9 5.19749C7.4475 5.19749 6.1875 6.45749 6.1875 8.00999C6.1875 9.53249 7.38 10.77 8.9625 10.815C8.985 10.815 9.015 10.815 9.03 10.815C9.045 10.815 9.0675 10.815 9.0825 10.815C9.09 10.815 9.0975 10.815 9.0975 10.815C10.6125 10.7625 11.805 9.53249 11.8125 8.00999C11.8125 6.45749 10.5525 5.19749 9 5.19749Z" fill="url(#paint1_linear_26_242)" />
            <defs>
                <linearGradient id="paint0_linear_26_242" x1="9" y1="1.50002" x2="9" y2="16.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <linearGradient id="paint1_linear_26_242" x1="9" y1="5.19749" x2="9" y2="10.815" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>

    );
}

