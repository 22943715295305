import './tbank.css';
import React, { useState, useEffect } from 'react';
import Alif from '../../QuickPay/Icons/Alif';
import Spitamen from '../../QuickPay/Icons/Spitamen';
import DC from '../../QuickPay/Icons/DC';
import Eskhata from '../../QuickPay/Icons/Eskhata';
import Amonat from '../../QuickPay/Icons/Amonat';
import Imon from '../../QuickPay/Icons/Imon';
import IBT from '../../QuickPay/Icons/IBT';
import Amra from '../../QuickPay/Icons/Amra';
import Matin from '../../QuickPay/Icons/Matin';

export default function SliderNumber({ bank, bankFull }) {
    const [slide, setSlide] = useState(0);

    const bank_logo = {
        spitamen: <Spitamen />,
        alif: <Alif />,
        dushanbe: <DC />,
        eskhata: <Eskhata />,
        amonat: <Amonat />,
        imon: <Imon />,
        mbt: <IBT />,
        amra: <Amra />,
        matin: <Matin />,
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide((prev) => (prev + 1) % slides.length);
        }, 3500);

        return () => clearInterval(interval);
    }, []);

    const slides = [
        {
            title: 'В разделе «Платежи» выберите «Перевод по телефону»',
            content: (
                <div className="contentAss">
                    <h3>Платежи</h3>
                    <div className="gapInputs">
                        <div className="fakeInput"><p>Платежи по телефону</p></div>
                        <div className="fakeInputNull"></div>
                        <div className="fakeInputNull"></div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Нажмите на телефонный код страны',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="country"><Ru /><p>+7</p></div>
                            <div className="find"><p>Номер или имя</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Выберите Таджикистан (+992)',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="countryFull"><Tj /><p>Таджикистан (+992)</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Введите номер указанный в реквизитах получателя',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="country"><Tj /><p>+992</p></div>
                            <div className="find"><p>Номер или имя</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: `Выберите банк ${bankFull || "Из реквизитов"}`,
            content: (
                <div className="contentAssBanks">
                    <div className="ourBank">
                        <div className="round">{bank_logo[bank]}</div>
                        <div className="text">{bankFull}</div>
                    </div>
                    <EmptyBank />
                    <EmptyBank />
                </div>
            ),
        },
        {
            title: 'Переведите сумму вашего платежа',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                    <button>Перевести</button>
                </div>
            ),
        },
    ];

    return (
        <div className="sliderContainer">
            <div className="slider">
                <div className="sliderTop">
                    <div className="sliderNumber"><p className="gradient">{slide + 1}</p></div>
                    <div className="sliderTitle"><p>{slides[slide].title}</p></div>
                </div>
                <div className="paymentAssistent" onClick={() => setSlide((slide + 1) % slides.length)}>{slides[slide].content}</div>
            </div>


            <div className="sliderNav">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        className={`sliderNavButton 
                            ${index < slide ? 'before-active' : ''}
                            ${index > slide ? 'after-active' : ''}
                            ${slide === index ? 'active' : ''}
                          `}
                        onClick={() => setSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
}


function Tj() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 512 512">
            <mask id="circleFlagsTj0">
                <circle cx={256} cy={256} r={256} fill="#fff"></circle>
            </mask>
            <g mask="url(#circleFlagsTj0)">
                <path fill="#eee" d="m0 144.7l255.3-36.5L512 144.7v222.6L250.5 407L0 367.3z"></path>
                <path fill="#d80027" d="M0 0h512v144.7H0z"></path>
                <path fill="#6da544" d="M0 367.3h512V512H0z"></path>
                <path fill="#ffda44" d="M211.5 323h89v-38l-17.8 9l-26.7-26.9l-26.7 26.7l-17.8-8.9zm-51-44.6l4.2 12.7H178l-10.9 8l4.2 12.7l-10.9-8l-10.8 8l4.1-12.8l-10.7-7.9h13.4zm12.9-44.6l4.1 12.8H191l-10.8 8l4.1 12.6l-10.8-7.9l-10.9 8l4.2-12.8l-10.9-8h13.4zm36.7-33.4l4.2 12.8h13.4l-10.9 8l4.2 12.6l-11-7.7l-10.8 7.8l4.1-12.7l-10.8-7.9H206zm141.4 78l-4.2 12.7H334l10.9 8l-4.2 12.7l10.9-8l10.8 8l-4.1-12.8l10.7-7.9h-13.4zm-12.9-44.6l-4.1 12.8H321l10.8 8l-4.1 12.6l10.8-7.9l10.9 8l-4.2-12.8l10.9-8h-13.4zM302 200.4l-4.2 12.8h-13.4l10.9 8l-4.2 12.6l10.9-7.7l10.8 7.8l-4.1-12.7l10.8-7.9H306zm-46-16.6l4.1 12.7h13.5l-10.9 8l4.1 12.6l-10.8-7.8l-10.8 7.8l4.1-12.7l-10.9-7.9H252z"></path>
            </g>
        </svg>
    );
}

function Az() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 512 512">
            <mask id="circleFlagsAz0">
                <circle cx={256} cy={256} r={256} fill="#fff"></circle>
            </mask>
            <g mask="url(#circleFlagsAz0)">
                <path fill="#d80027" d="m0 166.9l253-31.8l259 31.8v178l-257.5 37.4L0 345z"></path>
                <path fill="#338af3" d="M0 0h512v166.9H0z"></path>
                <path fill="#6da544" d="M0 344.9h512V512H0z"></path>
                <g fill="#fff">
                    <path d="M261.6 328.2a72.3 72.3 0 1 1 34.4-136a89 89 0 1 0 0 127.3a72 72 0 0 1-34.4 8.7"></path>
                    <path d="m317.2 206l9.6 26.8l25.8-12.3l-12.2 25.8l26.9 9.6l-27 9.6l12.3 25.8l-25.8-12.3l-9.6 27l-9.6-27l-25.8 12.3l12.3-25.8l-27-9.6l27-9.6l-12.3-25.8l25.8 12.3z"></path>
                </g>
            </g>
        </svg>
    );
}

function Ru() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 512 512">
            <mask id="circleFlagsLangChm0">
                <circle cx={256} cy={256} r={256} fill="#fff"></circle>
            </mask>
            <g mask="url(#circleFlagsLangChm0)">
                <path fill="#0052b4" d="M512 170v172l-256 32L0 342V170l256-32z"></path>
                <path fill="#eee" d="M512 0v170H0V0Z"></path>
                <path fill="#d80027" d="M512 342v170H0V342Z"></path>
            </g>
        </svg>
    );
}

function EmptyBank() {
    return (
        <div className="emptyBank">
            <div className="round"></div>
            <div className="text"></div>
        </div>
    );
}