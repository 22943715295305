import './tbank.css';
import React, { useState, useEffect } from 'react';

export default function SliderCard() {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide((prev) => (prev + 1) % slides.length);
        }, 3500);

        return () => clearInterval(interval);
    }, []);

    const slides = [
        {
            title: 'В разделе «Платежи» выберите «Перевод по номеру карты»',
            content: (
                <div className="contentAss">
                    <h3>Платежи</h3>
                    <div className="gapInputs">
                        <div className="fakeInput"><p>Платежи по номеру карты</p></div>
                        <div className="fakeInputNull"></div>
                        <div className="fakeInputNull"></div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Введите номер карты, указанный в реквизитах получателя',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="countryFull"><p>Введите номер карты</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Переведите сумму вашего платежа',
            content: (
                <div className="contentAss">
                    <h3>Перевод</h3>
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="countryFull"><p>Введите сумму</p></div>
                        </div>
                    </div>
                    <button>Перевести</button>
                </div>
            ),
        },
    ];

    return (
        <div className="sliderContainer">
            <div className="slider">
                <div className="sliderTop">
                    <div className="sliderNumber"><p className="gradient">{slide + 1}</p></div>
                    <div className="sliderTitle"><p>{slides[slide].title}</p></div>
                </div>
                <div className="paymentAssistent" onClick={() => setSlide((slide + 1) % slides.length)}>{slides[slide].content}</div>
            </div>


            <div className="sliderNav">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        className={`sliderNavButton 
                            ${index < slide ? 'before-active' : ''}
                            ${index > slide ? 'after-active' : ''}
                            ${slide === index ? 'active' : ''}
                          `}
                        onClick={() => setSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
}
