import React from 'react';

export default function Spitamen() {
    return (
        <svg width="36" height="36" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5045 30.8721C36.942 29.3445 39.4155 27.8495 41.8954 26.3597L26.4828 31.7324C24.2342 21.9128 28.2174 11.3476 37.1957 5.71998C46.4987 -0.112105 58.2293 1.19587 66.1108 8.12539C62.4084 11.0923 53.0261 18.891 49.8355 21.7015C55.3441 18.5358 60.9301 15.473 66.5782 12.5213C67.595 11.9766 68.4696 11.5177 69.0696 11.2111C69.0715 11.2125 69.0732 11.2152 69.0732 11.2175C69.9262 10.7777 70.7735 10.3297 71.63 9.89625C62.2093 -0.654983 46.3592 -3.25881 34.0336 4.46777C22.2057 11.8836 17.5173 26.3649 21.9291 39.0753C21.9909 39.0336 22.0543 38.9932 22.1185 38.9504C26.2293 36.1864 30.3567 33.4719 34.5045 30.8721V30.8721Z" fill="#525F7B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M95.3981 0.797607C88.5283 5.11386 81.5541 9.21466 74.5113 13.1469C48.0526 26.4993 23.5203 41.9441 0 60C23.2956 45.3611 47.6427 32.9166 72.716 22.7129C76.8391 33.3856 73.0121 45.7931 63.0051 52.0667C52.0809 58.915 37.7819 55.9526 30.328 45.5639C29.4621 46.0252 28.6045 46.5013 27.7412 46.9679C37.0408 58.3062 53.4788 61.2711 66.1648 53.3176C79.2984 45.0856 83.6295 28.1437 76.4714 14.5862C82.8599 10.1624 89.168 5.58027 95.3981 0.797607Z" fill="#FFD400" />
        </svg>


    );
}