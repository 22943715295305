import './waiting.css';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import SBP from '../SBP/SBP';
import React, { useEffect, useState } from 'react';

export default function Waiting({ cardInfo, setActiveStep, setCardInfo, sendFile }) {

    const [showReq, setShowReq] = useState(false);

    const fetchStatusAndUpdate = async () => {
        const getStatus = await fetchStatus();
        if (getStatus !== cardInfo.status) {
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: getStatus,
            }));
            if (getStatus === "NP") {
                setActiveStep(4);
            } else if (getStatus === "CL") {
                setActiveStep(6);
            } else if (getStatus === "AC") {
                setActiveStep(3);
            } else if (getStatus === "CF") {
                setActiveStep(5);
            } else if (getStatus === "CD") {
                setActiveStep(8);
            }
            return;
        }
    
        setTimeout(fetchStatusAndUpdate, 2000);
    };

    useEffect(() => {
        fetchStatusAndUpdate();
    }, [])

    const fetchStatus = async () => {
        const response = await fetch('/api/pay/status/' + cardInfo.id);
        if (response.ok) {
            const data = await response.json();
            return data.status;
        }
        return cardInfo.status;
    }

    const handleClick = async () => {
        const response = await fetch('/api/pay/status/' + cardInfo.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: "NP" })
        })
        if (response.ok) {
            const data = await response.json()
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: data.status,
                update: data.update,
            }))
            if (data.status === "CR") {
                setActiveStep(1);
            } else if (data.status === "OP") {
                setActiveStep(2);
            } else if (data.status === "NP") {
                setActiveStep(4);
            } else if (data.status === "CF") {
                setActiveStep(5);
            } else if (data.status === "CL") {
                setActiveStep(6);
            } else if (data.status === "AC") {
                setActiveStep(3);
            } else if (data.status === "CD") {
                setActiveStep(8);
            }

        }
    }

    return (
        <div className='waiting'>
            <InfoBlock cardInfo={cardInfo} timer={20} showReq={showReq} setShowReq={setShowReq} handleClick={handleClick} />
            <div className='waitingText'>
                {sendFile ? (
                    <>
                        <h2>Ожидайте проверки ваших доказательств</h2>
                        <p>Не закрывайте это окно. Ваш платеж подтвердится автоматически, после подтверждения Вы вернетесь обратно</p>
                    </>
                ) : cardInfo.type === "Ecom" ? (
                    <>
                        <h2>Обработка платежа</h2>
                        <p>Не закрывайте это окно</p>
                    </>
                ) : (
                    <>
                        <h2>Ищем Ваш платеж</h2>
                        <p>Не закрывайте это окно. Ваш платеж подтвердится автоматиески, после подтверждения Вы вернетесь обратно</p>
                    </>
                )}
                <div className='waitingLoop'>
                    <Loop />
                </div>
                {sendFile ? (
                    <div className='waitingNo'>
                        <p>Мы стараемся проверить как можно быстрее</p>
                    </div>
                ) : cardInfo.type !== "Ecom" && (
                    <div className='waitingNo' onClick={handleClick}>
                        <p>Не пришел платеж?</p>
                    </div>
                )}
            </div>
            {showReq && (<SBP cardInfo={cardInfo} onlyReq={true} />)}
        </div>
    );

}

function Loop() {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50 7.5058C50 3.36046 53.379 -0.0576348 57.4777 0.56232C61.4711 1.16634 65.3858 2.25339 69.1342 3.80602C75.2005 6.31876 80.7124 10.0017 85.3553 14.6447C89.9983 19.2876 93.6812 24.7996 96.194 30.8658C97.7466 34.6142 98.8337 38.5289 99.4377 42.5223C100.058 46.621 96.6395 50 92.4942 50C88.3489 50 85.0689 46.6019 84.1864 42.5516C83.7448 40.5247 83.1227 38.5362 82.3251 36.6105C80.5667 32.3655 77.9895 28.5084 74.7405 25.2595C71.4916 22.0105 67.6345 19.4333 63.3895 17.6749C61.4638 16.8773 59.4753 16.2552 57.4484 15.8136C53.3981 14.9311 50 11.6511 50 7.5058Z" fill="url(#paint0_linear_44_3215)" />
            <defs>
                <linearGradient id="paint0_linear_44_3215" x1="94" y1="50" x2="45.646" y2="8.58323" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F700FF" />
                    <stop offset="1" stop-color="#F3C2F9" stop-opacity="0.1" />
                </linearGradient>
            </defs>
        </svg>

    );
}

function Timer({ startTime, payId, setActiveStep, setCardInfo }) {
    const [timeLeft, setTimeLeft] = useState(120000);

    const handleClick = async () => {
        const response = await fetch('/api/pay/status/' + payId, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: "NP" })
        })
        if (response.ok) {
            const data = await response.json()
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: data.status,
                update: data.update,
            }))
            if (data.status === "CR") {
                setActiveStep(1);
            } else if (data.status === "OP") {
                setActiveStep(2);
            } else if (data.status === "NP") {
                setActiveStep(4);
            } else if (data.status === "CF") {
                setActiveStep(5);
            } else if (data.status === "CL") {
                setActiveStep(6);
            } else if (data.status === "AC") {
                setActiveStep(3);
            }
        }
    }

    useEffect(() => {
        const paymentTime = new Date(startTime);
        const countdownDuration = 2 * 60 * 1000;
        const endTime = paymentTime.getTime() + countdownDuration;

        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const timeRemaining = Math.max(endTime - currentTime, 0);
            setTimeLeft(timeRemaining);

            if (timeRemaining <= 0) {
                clearInterval(interval);
                handleClick();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [startTime]);

    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / 1000 / 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className='waitingTimer'>
            <svg width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 5.00391V10.0039H15M10 19.0039C5.02944 19.0039 1 14.9745 1 10.0039C1 5.03334 5.02944 1.00391 10 1.00391C14.9706 1.00391 19 5.03334 19 10.0039C19 14.9745 14.9706 19.0039 10 19.0039Z" stroke="url(#paint0_linear_43_2119)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_43_2119" x1="10" y1="20.0039" x2="10" y2="0.00390612" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0088FF" />
                        <stop offset="1" stopColor="#9BCCF9" />
                    </linearGradient>
                </defs>
            </svg>
            <p>{formatTimeLeft()}</p>
        </div>
    );
}