import './sber.css';
import React, { useState, useEffect } from 'react';

export default function SliderSber({ amount, country }) {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide((prev) => (prev + 1) % slides.length);
        }, 3500);

        return () => clearInterval(interval);
    }, []);

    const slides = [
        {
            title: 'Откройте раздел «Платежи»',
            content: (
                <div className="contentAss">
                    <h3>Платежи</h3>
                    <div className="gapInputs" style={{ gap: "12px" }}>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeMenu">
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull"></div>
                            </div>
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull"></div>
                            </div>
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull"></div>
                            </div>
                            <div className="fakeMenuBlock">
                                <div className="fakeMenuIco"><CardIco /></div>
                                <div className="fakeMenuText">Платежи</div>
                            </div>
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Выберите перевод в другую страну',
            content: (
                <div className="contentAss">
                    <h3>Платежи</h3>
                    <div className="gapInputs" style={{ gap: "12px" }}>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeMenu">
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull" style={{ width: "36.5px", height: "15px" }}></div>
                            </div>
                            <div className="fakeMenuBlockCountry">
                                <div className="fakeMenuIco"><GlobalIco /></div>
                                <div className="fakeMenuText">В другую страну</div>
                            </div>
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull" style={{ width: "36.5px", height: "15px" }}></div>
                            </div>
                            <div className="fakeMenuNull">
                                <div className="fakeChooseRoundNull"></div>
                                <div className="fakeMenuLineNull" style={{ width: "36.5px", height: "15px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Выберите ' + country.country,
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChoose">
                            <div className="countryFull">{country.flag}<p>{country.country}</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Введите номер указанный в реквизитах получателя',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="country">{country.flag}<p>{country.number}</p></div>
                            <div className="find"><p>Номер или имя</p></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                        <div className="fakeInputChooseNull">
                            <div className="fakeChooseRoundNull"></div>
                            <div className="fakeChooseNull"></div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Переведите сумму вашего платежа',
            content: (
                <div className="contentAss">
                    <div className="gapInputs" style={{ gap: "15px" }}>
                        <div className="fakeInputChoose">
                            <div className="countryFull"><p>{amount.toLocaleString('ru-RU')} ₽</p></div>
                        </div>
                    </div>
                    <button>Перевести</button>
                </div>
            ),
        },
    ];

    return (
        <div className="sliderContainer">
            <div className="slider">
                <div className="sliderTop">
                    <div className="sliderNumber"><p className="gradient">{slide + 1}</p></div>
                    <div className="sliderTitle"><p>{slides[slide].title}</p></div>
                </div>
                <div className="paymentAssistent" onClick={() => setSlide((slide + 1) % slides.length)}>{slides[slide].content}</div>
            </div>


            <div className="sliderNav">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        className={`sliderNavButton 
                            ${index < slide ? 'before-active' : ''}
                            ${index > slide ? 'after-active' : ''}
                            ${slide === index ? 'active' : ''}
                          `}
                        onClick={() => setSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
}


function CardIco() {

    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0996 5.66249C17.0996 6.15749 16.6946 6.56249 16.1996 6.56249H2.99961C2.50461 6.56249 2.09961 6.15749 2.09961 5.66249V5.65499C2.09961 3.93749 3.48711 2.54999 5.20461 2.54999H13.9871C15.7046 2.54999 17.0996 3.94499 17.0996 5.66249Z" fill="url(#paint0_linear_79_166)" />
            <path d="M2.09961 8.5875V12.345C2.09961 14.0625 3.48711 15.45 5.20461 15.45H13.9871C15.7046 15.45 17.0996 14.055 17.0996 12.3375V8.5875C17.0996 8.0925 16.6946 7.6875 16.1996 7.6875H2.99961C2.50461 7.6875 2.09961 8.0925 2.09961 8.5875ZM6.59961 12.9375H5.09961C4.79211 12.9375 4.53711 12.6825 4.53711 12.375C4.53711 12.0675 4.79211 11.8125 5.09961 11.8125H6.59961C6.90711 11.8125 7.16211 12.0675 7.16211 12.375C7.16211 12.6825 6.90711 12.9375 6.59961 12.9375ZM11.4746 12.9375H8.47461C8.16711 12.9375 7.91211 12.6825 7.91211 12.375C7.91211 12.0675 8.16711 11.8125 8.47461 11.8125H11.4746C11.7821 11.8125 12.0371 12.0675 12.0371 12.375C12.0371 12.6825 11.7821 12.9375 11.4746 12.9375Z" fill="url(#paint1_linear_79_166)" />
            <defs>
                <linearGradient id="paint0_linear_79_166" x1="15.8282" y1="2.73221" x2="14.2824" y2="9.06001" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint1_linear_79_166" x1="15.8282" y1="8.04002" x2="10.8169" y2="18.6438" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

function GlobalIco() {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.48742 15.6825C6.46492 15.6825 6.43492 15.6975 6.41242 15.6975C4.95742 14.9775 3.77242 13.785 3.04492 12.33C3.04492 12.3075 3.05992 12.2775 3.05992 12.255C3.97492 12.525 4.91992 12.7275 5.85742 12.885C6.02242 13.83 6.21742 14.7675 6.48742 15.6825Z" fill="url(#paint0_linear_79_322)" />
            <path d="M16.4553 12.3375C15.7128 13.83 14.4753 15.0375 12.9678 15.765C13.2528 14.8125 13.4928 13.8525 13.6503 12.885C14.5953 12.7275 15.5253 12.525 16.4403 12.255C16.4328 12.285 16.4553 12.315 16.4553 12.3375Z" fill="url(#paint1_linear_79_322)" />
            <path d="M16.5153 5.78252C15.5703 5.49752 14.6178 5.26502 13.6503 5.10002C13.4928 4.13252 13.2603 3.17252 12.9678 2.23502C14.5203 2.97752 15.7728 4.23002 16.5153 5.78252Z" fill="url(#paint2_linear_79_322)" />
            <path d="M6.48785 2.3175C6.21785 3.2325 6.02285 4.1625 5.86535 5.1075C4.89785 5.2575 3.93785 5.4975 2.98535 5.7825C3.71285 4.275 4.92035 3.0375 6.41285 2.295C6.43535 2.295 6.46535 2.3175 6.48785 2.3175Z" fill="url(#paint3_linear_79_322)" />
            <path d="M12.3678 4.9425C10.6278 4.7475 8.87281 4.7475 7.13281 4.9425C7.32031 3.915 7.56031 2.8875 7.89781 1.8975C7.91281 1.8375 7.90531 1.7925 7.91281 1.7325C8.50531 1.59 9.11281 1.5 9.75031 1.5C10.3803 1.5 10.9953 1.59 11.5803 1.7325C11.5878 1.7925 11.5878 1.8375 11.6028 1.8975C11.9403 2.895 12.1803 3.915 12.3678 4.9425Z" fill="url(#paint4_linear_79_322)" />
            <path d="M5.6925 11.6175C4.6575 11.43 3.6375 11.19 2.6475 10.8525C2.5875 10.8375 2.5425 10.845 2.4825 10.8375C2.34 10.245 2.25 9.63751 2.25 9.00001C2.25 8.37001 2.34 7.75501 2.4825 7.17001C2.5425 7.16251 2.5875 7.16251 2.6475 7.14751C3.645 6.81751 4.6575 6.57001 5.6925 6.38251C5.505 8.12251 5.505 9.87751 5.6925 11.6175Z" fill="url(#paint5_linear_79_322)" />
            <path d="M17.2501 9.00001C17.2501 9.63751 17.1601 10.245 17.0176 10.8375C16.9576 10.845 16.9126 10.8375 16.8526 10.8525C15.8551 11.1825 14.8351 11.43 13.8076 11.6175C14.0026 9.87751 14.0026 8.12251 13.8076 6.38251C14.8351 6.57001 15.8626 6.81001 16.8526 7.14751C16.9126 7.16251 16.9576 7.17001 17.0176 7.17001C17.1601 7.76251 17.2501 8.37001 17.2501 9.00001Z" fill="url(#paint6_linear_79_322)" />
            <path d="M12.3678 13.0575C12.1803 14.0925 11.9403 15.1125 11.6028 16.1025C11.5878 16.1625 11.5878 16.2075 11.5803 16.2675C10.9953 16.41 10.3803 16.5 9.75031 16.5C9.11281 16.5 8.50531 16.41 7.91281 16.2675C7.90531 16.2075 7.91281 16.1625 7.89781 16.1025C7.56781 15.105 7.32031 14.0925 7.13281 13.0575C8.00281 13.155 8.87281 13.2225 9.75031 13.2225C10.6278 13.2225 11.5053 13.155 12.3678 13.0575Z" fill="url(#paint7_linear_79_322)" />
            <path d="M12.5725 11.8225C10.6967 12.0592 8.80332 12.0592 6.9275 11.8225C6.69083 9.94668 6.69083 8.05332 6.9275 6.1775C8.80332 5.94083 10.6967 5.94083 12.5725 6.1775C12.8092 8.05332 12.8092 9.94668 12.5725 11.8225Z" fill="url(#paint8_linear_79_322)" />
            <defs>
                <linearGradient id="paint0_linear_79_322" x1="6.19564" y1="12.4113" x2="3.33101" y2="15.5481" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint1_linear_79_322" x1="16.1597" y1="12.4144" x2="13.2372" y2="15.594" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint2_linear_79_322" x1="16.2146" y1="2.39612" x2="13.2626" y2="5.62859" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint3_linear_79_322" x1="6.19099" y1="2.45338" x2="3.29006" y2="5.64357" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint4_linear_79_322" x1="11.9241" y1="1.65633" x2="9.38499" y2="5.88439" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint5_linear_79_322" x1="5.40072" y1="6.62024" x2="1.25225" y2="9.60743" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint6_linear_79_322" x1="16.9583" y1="6.62024" x2="12.8099" y2="9.60743" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint7_linear_79_322" x1="11.9241" y1="13.2138" x2="9.38499" y2="17.4419" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
                <linearGradient id="paint8_linear_79_322" x1="12.2415" y1="6.27248" x2="7.24862" y2="11.7397" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
            </defs>
        </svg>

    );
}

function Az() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 512 512">
            <mask id="circleFlagsAz0">
                <circle cx={256} cy={256} r={256} fill="#fff"></circle>
            </mask>
            <g mask="url(#circleFlagsAz0)">
                <path fill="#d80027" d="m0 166.9l253-31.8l259 31.8v178l-257.5 37.4L0 345z"></path>
                <path fill="#338af3" d="M0 0h512v166.9H0z"></path>
                <path fill="#6da544" d="M0 344.9h512V512H0z"></path>
                <g fill="#fff">
                    <path d="M261.6 328.2a72.3 72.3 0 1 1 34.4-136a89 89 0 1 0 0 127.3a72 72 0 0 1-34.4 8.7"></path>
                    <path d="m317.2 206l9.6 26.8l25.8-12.3l-12.2 25.8l26.9 9.6l-27 9.6l12.3 25.8l-25.8-12.3l-9.6 27l-9.6-27l-25.8 12.3l12.3-25.8l-27-9.6l27-9.6l-12.3-25.8l25.8 12.3z"></path>
                </g>
            </g>
        </svg>
    );
}