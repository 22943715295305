import './loading.css';
import React from 'react';

export default function Loading() {

    return (
        <div className='loadingLoop'>
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 7.5058C50 3.36046 53.379 -0.0576348 57.4777 0.56232C61.4711 1.16634 65.3858 2.25339 69.1342 3.80602C75.2005 6.31876 80.7124 10.0017 85.3553 14.6447C89.9983 19.2876 93.6812 24.7996 96.194 30.8658C97.7466 34.6142 98.8337 38.5289 99.4377 42.5223C100.058 46.621 96.6395 50 92.4942 50C88.3489 50 85.0689 46.6019 84.1864 42.5516C83.7448 40.5247 83.1227 38.5362 82.3251 36.6105C80.5667 32.3655 77.9895 28.5084 74.7405 25.2595C71.4916 22.0105 67.6345 19.4333 63.3895 17.6749C61.4638 16.8773 59.4753 16.2552 57.4484 15.8136C53.3981 14.9311 50 11.6511 50 7.5058Z" fill="url(#paint0_linear_44_3215)" />
                <defs>
                    <linearGradient id="paint0_linear_44_3215" x1="94" y1="50" x2="45.646" y2="8.58323" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F700FF" />
                        <stop offset="1" stopColor="#F3C2F9" stopOpacity="0.1" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}