import './payment.css';
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import SBP from '../../components/SBP/SBP';
import SBPsber from '../../components/SBP/SBPsber';
import CryptoJS from "crypto-js";
import Loading from '../../components/Loading/Loading';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import QuickPay from '../../components/QuickPay/QuickPay';
import Ecom from '../../components/Ecom/Ecom';
import QRCode from "react-qr-code";

const secretKey = "DfdaNjwefUyuidsnfdsf";
const METRIKA_ID = 99785423;

const currencySymbol = {
    RUB: "₽",
    AZN: "₼",
}

export default function Payment({ cardInfo, setActiveStep, setCardInfo }) {
    const mobile = window.matchMedia('(max-width: 1000px)').matches;

    const encryptedIOS = "U2FsdGVkX19R6VAp+U3lF/AmLX1pp3Eo1XJRPVbaLjEXvRjCaSSBjDvh0kx9rdrc7nPBMJTlC+LRzLFYH0NyQnWKSpxH4DDX4XI+x7lyacgA0zeJ+dyzBGm9DS4/AI2GdtnAd2q177OT8nwMEx8z7CR2BiZB3OI+CKepRj6iYZE=";
    const encryptedAndroid = "U2FsdGVkX1/C6HjybuoO5fLQlfrsgwSjz08HGIXNkchTbiAmTxxn6rCi5z9hfSfPUjBbMBEiM/saD1La843UiTuuul3hS+8E9QhM8jbBzjij7RACDOKOswckFw8BYIMzrbqR/KPxnQPIUdDVCK4LJvGD2VOJoGR8SkVJFgGNyCs=";

    useEffect(() => {
        if (cardInfo.type === "TPay" && cardInfo.card_info === cardInfo.tpay) {
            const device = getDeviceType();
            if (device === "iOS") {
                handleClickTPay();
            }
        }
    }, [])
    console.log(window.location);
    const [loading, setLoading] = useState(false);
    const [showSBP, setShowSBP] = useState(false);

    useEffect(() => {
        if (showSBP) {
            if (window.ym) {
                window.ym(METRIKA_ID, "reachGoal", "click_sbp");
            } else {
                console.warn("Яндекс.Метрика ещё не загружена");
            }
        } else {
            if (window.ym) {
                window.ym(METRIKA_ID, "reachGoal", "return_quick");
            } else {
                console.warn("Яндекс.Метрика ещё не загружена");
            }
        }
    }, [showSBP])

    const handleClick = async (statusSend) => {
        if (statusSend === "OP") {
            if (window.ym) {
                window.ym(METRIKA_ID, "reachGoal", "click_pay");
            } else {
                console.warn("Яндекс.Метрика ещё не загружена");
            }
        } else if (statusSend === "CL") {
            if (window.ym) {
                window.ym(METRIKA_ID, "reachGoal", "click_cancel");
            } else {
                console.warn("Яндекс.Метрика ещё не загружена");
            }
        }
        setLoading(true);
        const response = await fetch('/api/pay/status/' + cardInfo.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: statusSend })
        })
        if (response.ok) {
            const data = await response.json()
            setCardInfo(prevInfo => ({
                ...prevInfo,
                status: data.status,
                update: data.update,
            }))
            if (data.status === "CR") {
                setActiveStep(1);
            } else if (data.status === "OP") {
                setActiveStep(2);
            } else if (data.status === "NP") {
                setActiveStep(4);
            } else if (data.status === "CF") {
                setActiveStep(5);
            } else if (data.status === "CL") {
                setActiveStep(6);
            } else if (data.status === "AC") {
                setActiveStep(3);
            }
        }
        setLoading(false);
    }

    function decryptUrl(encryptedUrl) {
        const bytes = CryptoJS.AES.decrypt(encryptedUrl, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    const handleClickSberPay = () => {
        const url = "https://www.sberbank.com/sms/pbpn?requisiteNumber=" + encodeURIComponent(cardInfo.card_info.replaceAll(' ', ''));
        window.open(url, '_blank');
    }

    const getDeviceType = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        } else if (/android/i.test(userAgent)) {
            return "Android";
        }
        return "Unknown";
    }

    const handleClickTPay = () => {
        if (cardInfo.tpay !== null) {
            redirectToBank(cardInfo.tpay, cardInfo.amount, cardInfo.bank_id ? cardInfo.bank_id : 10037)
            if (window.ym) {
                window.ym(METRIKA_ID, "reachGoal", "click_tpay");
            } else {
                console.warn("Яндекс.Метрика ещё не загружена");
            }
        }
    };

    const redirectToBank = (numberPhone, amount, bankMemberId) => {
        const deviceType = getDeviceType();
        const encryptedUrl = deviceType === "iOS" ? encryptedIOS : encryptedAndroid;
        const baseUrl = decryptUrl(encryptedUrl);
        const finalUrl = baseUrl
            .replace("{numberPhone}", encodeURIComponent(numberPhone.replaceAll(' ', '')))
            .replace("{amount}", encodeURIComponent(amount))
            .replace("{bankMemberId}", encodeURIComponent(bankMemberId));
        window.location.href = finalUrl;
    }

    return (
        <div className='payment'>
            {mobile ? (
                <>
                    <InfoBlock cardInfo={cardInfo} timer={12} handleClick={handleClick} />
                    {(cardInfo.type === "SberPay" || (cardInfo.type === "TPay" && cardInfo.card_info === cardInfo.tpay )) && (
                        <AnimatePresence mode="wait">
                            {showSBP ? (
                                <motion.div
                                    key="sbp"
                                    initial={{ x: "100%", opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: "100%", opacity: 0 }}
                                    transition={{ type: "spring", stiffness: 100, damping: 15 }}
                                >
                                    {cardInfo.type === "SberPay" ? (
                                        <SBPsber cardInfo={cardInfo} setShowSBP={setShowSBP} onlyReq={false} />
                                    ) : (
                                        <SBP cardInfo={cardInfo} setShowSBP={setShowSBP} onlyReq={false} />
                                    )}
                                    <button className='ready' onClick={() => handleClick("OP")}>Я оплатил</button>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="quickpay"
                                    initial={{ x: "-100%", opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: "-100%", opacity: 0 }}
                                    transition={{ type: "spring", stiffness: 100, damping: 15 }}
                                >
                                    <QuickPay cardInfo={cardInfo} setShowSBP={setShowSBP} tpay={handleClickTPay} sberpay={handleClickSberPay} />
                                    <button className='ready' onClick={() => handleClick("OP")}>Я оплатил</button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    )}
                    {(cardInfo.type === "C2C" || cardInfo.type === "SBP" || (cardInfo.type === "TPay" && cardInfo.card_info !== cardInfo.tpay ) || cardInfo.type === "VTB" || cardInfo.type === "ALFA") && (
                        <>
                            <QuickPay cardInfo={cardInfo} setShowSBP={setShowSBP} tpay={handleClickTPay} sberpay={handleClickSberPay} />
                            <button className='ready' onClick={() => handleClick("OP")}>Я оплатил</button>
                        </>
                    )}
                    {cardInfo.type === "Ecom" && (
                        <>
                            <Ecom cardInfo={cardInfo} setCardInfo={setCardInfo} setActiveStep={setActiveStep} />
                        </>
                    )}

                </>
            ) : (
                <div className="Desktop">
                    <h1>Откройте платежное окно с мобильного устройства</h1>
                    <QRCode
                        size={"128px"}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={window.location.href}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            )}
            {loading && (
                <div className='paymentBlur'>
                    <div className='paymentLoading'>
                        <Loading />
                    </div>
                </div>
            )}
        </div>
    );
}
