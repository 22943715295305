import './tbank.css';
import React from "react";
import Alif from '../../QuickPay/Icons/Alif';
import Spitamen from '../../QuickPay/Icons/Spitamen';
import DC from '../../QuickPay/Icons/DC';
import Eskhata from '../../QuickPay/Icons/Eskhata';
import Amonat from '../../QuickPay/Icons/Amonat';
import Imon from '../../QuickPay/Icons/Imon';
import IBT from '../../QuickPay/Icons/IBT';
import Amra from '../../QuickPay/Icons/Amra';
import Matin from '../../QuickPay/Icons/Matin';


export default function Bank({ bank, bankFull }) {

    const bank_logo = {
        spitamen: <Spitamen />,
        alif: <Alif />,
        dushanbe: <DC />,
        eskhata: <Eskhata />,
        amonat: <Amonat />,
        imon: <Imon />,
        mbt: <IBT />,
        amra: <Amra />,
        matin: <Matin />,
    }
    return (
        <div className="bank">
            <p>Выберите <span className="chooseBank">{bankFull}</span> для перевода</p>
            <div className="choose">
                <div className="ourBank">
                    <div className="round">{bank_logo[bank]}</div>
                    <div className="text">{bankFull}</div>
                </div>
                <EmptyBank />
                <EmptyBank />
                <EmptyBank />
            </div>
        </div>
    );
}

function EmptyBank() {
    return (
        <div className="emptyBank">
            <div className="round"></div>
            <div className="text"></div>
        </div>
    );
}