import './infoblock.css'
import React, { useState, useEffect } from 'react';
import Copy from '../Copy/Copy';

const METRIKA_ID = 99785423;

const currencySymbol = {
    RUB: "₽",
    AZN: "₼",
}

export default function InfoBlock({ cardInfo, timer, showReq, setShowReq, handleClick }) {

    const [copy, setCopy] = useState(false);
    const [closePay, setClosePay] = useState(false);

    const prepareId = (id) => {
        return `${id.slice(0, 6)}...${id.slice(-6)}`;
    };

    const handleCopy = () => {
        if (window.ym) {
            window.ym(METRIKA_ID, "reachGoal", "click_copy_id");
        } else {
            console.warn("Яндекс.Метрика ещё не загружена");
        }
        if (navigator.clipboard) {
            navigator.clipboard.writeText(cardInfo.id).then(() => {
                setCopy(true);
            }).catch(() => {
                fallbackCopyTextToClipboard(cardInfo.id);
            });
        } else {
            fallbackCopyTextToClipboard(cardInfo.id);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setCopy(true);
        } catch (err) {
            console.error('Не удалось скопировать текст', err);
        }

        document.body.removeChild(textArea);
    }

    return (
        <header>
            <div className="close" style={{ justifyContent: cardInfo.status === "OP" ? "space-between" : "flex-end" }}>
                {cardInfo.status === "OP" && cardInfo.type !== "Ecom" && (
                    <div className="back" onClick={() => { setShowReq(!showReq) }}>
                        <Row />
                        {!showReq ? (
                            <span>К реквизитам</span>
                        ) : (
                            <span>Скрыть</span>
                        )}
                    </div>
                )}
                {cardInfo.status === "CR" && (
                    <div className="closeButton" onClick={() => { setClosePay(true) }}>
                        <Close />
                    </div>
                )}
            </div>
            <div className="container">
                <div className="sum">
                    <p>Оплата</p>
                    {cardInfo.currency === "RUB" && (
                        <span className="gradient">{cardInfo?.amount?.toLocaleString('ru-RU')} {currencySymbol[cardInfo.currency]}</span>
                    )}
                    {cardInfo.currency === "AZN" && (
                        <span className="amonat">{cardInfo?.amount?.toLocaleString('ru-RU')} {currencySymbol[cardInfo.currency]}</span>
                    )}
                </div>
                {cardInfo.id && (
                    <div className="info">
                        {(cardInfo.status === "CR" || cardInfo.status === "OP" || cardInfo.status === 'CD') && (
                            <Timer startTime={cardInfo.update} duration={timer} handleClick={handleClick} />
                        )}
                        <div className="id" onClick={handleCopy}>
                            <span>#{prepareId(cardInfo.id)}</span>
                            <Row />
                        </div>
                    </div>
                )}
            </div>
            <Copy copy={copy} onHide={() => setCopy(false)} text={"ID Сделки успешно скопировано"} />
            <CloseBlock isOpen={closePay} onClose={() => setClosePay(false)} handleClick={handleClick} />
        </header>

    );
}

function Timer({ startTime, duration, handleClick }) {
    const [timeLeft, setTimeLeft] = useState(0);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        const paymentTime = new Date(startTime);
        const countdownDuration = duration * 60 * 1000;
        const endTime = paymentTime.getTime() + countdownDuration;

        const currentTime = new Date().getTime();
        const initialTimeRemaining = Math.max(endTime - currentTime, 0);
        setTimeLeft(initialTimeRemaining);

        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const timeRemaining = Math.max(endTime - currentTime, 0);
            setTimeLeft(timeRemaining);

            if (timeRemaining <= 0) {
                clearInterval(interval);
                handleClick("CL");
            }

            if (timeRemaining <= 5 * 60 * 1000 && timeRemaining > 5 * 60 * 1000 - 1000) {
                setShowWarning(true);
            }


        }, 1000);

        return () => clearInterval(interval);
    }, [startTime]);

    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / 1000 / 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className='timerBlock'>
            <Clock />
            <span>{formatTimeLeft()}</span>
            <Warning isOpen={showWarning} onClose={() => setShowWarning(false)} />
        </div>
    );
}

function CloseBlock({ isOpen, onClose, handleClick }) {

    return (
        <div className={`closeWrapper ${isOpen ? "show" : ""}`} onClick={onClose}>
            <div className="closeBlock" onClick={(e) => e.stopPropagation()}>
                <h2>Вы уверены, что хотите отклонить сделку?</h2>
                <div className="closeButtons">
                    <button onClick={onClose}>Нет</button>
                    <button onClick={() => handleClick("CL")}>Да</button>
                </div>
            </div>
        </div>
    );
}

function Warning({ isOpen, onClose }) {

    return (
        <div className={`warningWrapper ${isOpen ? "show" : ""}`} onClick={onClose}>
            <div className="warningBlock" onClick={(e) => e.stopPropagation()}>
                <div className="tapWarning">
                    <Tap />
                </div>
                <p>Если вы перевели средства, то нажмите кнопку «<span>Я оплатил</span>»</p>
                <div className="closeWarning" onClick={onClose}>
                    <Close />
                </div>
            </div>
        </div>
    );
}

function Tap() {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.26 22.25H8.73998C3.82998 22.25 1.72998 20.15 1.72998 15.24V15.11C1.72998 10.67 3.47998 8.53 7.39998 8.16C7.79998 8.13 8.17998 8.43 8.21998 8.84C8.25998 9.25 7.95998 9.62 7.53998 9.66C4.39998 9.95 3.22998 11.43 3.22998 15.12V15.25C3.22998 19.32 4.66998 20.76 8.73998 20.76H15.26C19.33 20.76 20.77 19.32 20.77 15.25V15.12C20.77 11.41 19.58 9.93 16.38 9.66C15.97 9.62 15.66 9.26 15.7 8.85C15.74 8.44 16.09 8.13 16.51 8.17C20.49 8.51 22.27 10.66 22.27 15.13V15.26C22.27 20.15 20.17 22.25 15.26 22.25Z" fill="url(#paint_grad)" />
            <path d="M12 15.63C11.59 15.63 11.25 15.29 11.25 14.88V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V14.88C12.75 15.3 12.41 15.63 12 15.63Z" fill="#292D32" />
            <path d="M11.9998 16.75C11.8098 16.75 11.6198 16.68 11.4698 16.53L8.11984 13.18C7.82984 12.89 7.82984 12.41 8.11984 12.12C8.40984 11.83 8.88984 11.83 9.17984 12.12L11.9998 14.94L14.8198 12.12C15.1098 11.83 15.5898 11.83 15.8798 12.12C16.1698 12.41 16.1698 12.89 15.8798 13.18L12.5298 16.53C12.3798 16.68 12.1898 16.75 11.9998 16.75Z" fill="#292D32" />
            <defs>
                <linearGradient id="paint_grad" x1="8" y1="0.5" x2="8" y2="15.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

function Close() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.87703 11.685C6.73453 11.685 6.59203 11.6325 6.47953 11.52C6.26203 11.3025 6.26203 10.9425 6.47953 10.725L10.7245 6.48002C10.942 6.26252 11.302 6.26252 11.5195 6.48002C11.737 6.69752 11.737 7.05752 11.5195 7.27502L7.27453 11.52C7.16953 11.6325 7.01953 11.685 6.87703 11.685Z" fill="#7A8699" />
            <path d="M11.122 11.685C10.9795 11.685 10.837 11.6325 10.7245 11.52L6.47953 7.27502C6.26203 7.05752 6.26203 6.69752 6.47953 6.48002C6.69703 6.26252 7.05703 6.26252 7.27453 6.48002L11.5195 10.725C11.737 10.9425 11.737 11.3025 11.5195 11.52C11.407 11.6325 11.2645 11.685 11.122 11.685Z" fill="#7A8699" />
            <path d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H11.25C15.3225 0.9375 17.0625 2.6775 17.0625 6.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625ZM6.75 2.0625C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V6.75C15.9375 3.2925 14.7075 2.0625 11.25 2.0625H6.75Z" fill="#7A8699" />
        </svg>
    );
}

function Clock() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.5C3.8675 0.5 0.5 3.8675 0.5 8C0.5 12.1325 3.8675 15.5 8 15.5C12.1325 15.5 15.5 12.1325 15.5 8C15.5 3.8675 12.1325 0.5 8 0.5ZM11.2625 10.6775C11.1575 10.8575 10.97 10.955 10.775 10.955C10.6775 10.955 10.58 10.9325 10.49 10.8725L8.165 9.485C7.5875 9.14 7.16 8.3825 7.16 7.715V4.64C7.16 4.3325 7.415 4.0775 7.7225 4.0775C8.03 4.0775 8.285 4.3325 8.285 4.64V7.715C8.285 7.985 8.51 8.3825 8.7425 8.5175L11.0675 9.905C11.3375 10.0625 11.4275 10.4075 11.2625 10.6775Z" fill="url(#paint0_linear_26_43)" />
            <defs>
                <linearGradient id="paint0_linear_26_43" x1="8" y1="0.5" x2="8" y2="15.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>

    );
}

function Row() {
    return (
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.72975 4.52401L5.35161 8.14587C5.54556 8.33982 5.54556 8.65427 5.35161 8.84821C5.15767 9.04216 4.84322 9.04216 4.64927 8.84821L0.746474 4.94542C0.513742 4.71268 0.513738 4.33535 0.746474 4.10261L4.64927 0.199811C4.84322 0.00586542 5.15767 0.00586541 5.35161 0.199811C5.54556 0.393757 5.54556 0.708205 5.35161 0.90215L1.72975 4.52401Z" fill="#7A8699" />
        </svg>

    );
}
