import './end.css';
import React, { useState } from 'react';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import Loading from '../Loading/Loading';

export default function End({ success, close, cardInfo, setCardInfo, setActiveStep }) {
    const [click, setClick] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setClick(true);
    }

    const handleFileChange = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64File = reader.result;
                const bodyRequest = {
                    file_name: file.name,
                    file_data: base64File
                }

                const response = await fetch('/api/pay/file/' + cardInfo.id, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyRequest)
                })
                if (response.ok) {
                    const data = await response.json();
                    setCardInfo(prevInfo => ({
                        ...prevInfo,
                        status: data.status,
                        update: data.update,
                    }))
                    if (data.status === "CR") {
                        setActiveStep(1);
                    } else if (data.status === "OP") {
                        setActiveStep(2);
                    } else if (data.status === "NP") {
                        setActiveStep(4);
                    } else if (data.status === "CF") {
                        setActiveStep(5);
                    } else if (data.status === "CL") {
                        setActiveStep(6);
                    } else if (data.status === "SF") {
                        setActiveStep(7);
                    } else if (data.status === "AC") {
                        setActiveStep(3);
                    }
                }
                setLoading(false);
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <div className='success'>
            <InfoBlock cardInfo={cardInfo} />
            {success && (
                <>
                    <div className='successText'>
                        <h2>Платеж успешен</h2>
                        <p>Мы подтвердили перевод, средства зачислены</p>
                        <div className='successDone'>
                            <Done />
                        </div>
                    </div>
                </>
            )}
            {close && (
                <>
                    <div className='successText'>
                        <h2>Платеж отменен</h2>
                        <p>К сожалению время оплаты вышло, попробуйте еще раз</p>
                        <div className='successDone'>
                            <Error />
                        </div>
                    </div>

                </>
            )}
            {!click && !success && !close && (
                <>
                    <div className='successText'>
                        <h2>Ошибка операции</h2>
                        <p>Пожалуйста, подтвердите Ваш перевод</p>
                        <div className='successDone'>
                            <Error />
                        </div>
                        <div className='successButton'>
                            <button onClick={handleClick}>Не пришел платеж</button>
                        </div>
                    </div>


                </>
            )}
            {click && (
                <>
                    <div className='successText'>
                        <h2>Подтвердите платеж</h2>
                        <p>Для подтверждения сделки необходимо отправить чек о переводе</p>
                        <div className='successFile'>
                            <input
                                type='file'
                                id='fileInput'
                                accept='.jpg, .png, .pdf'
                                onChange={handleFileChange}
                            />
                            <label htmlFor="fileInput">
                                <File />
                                Отправить файл
                            </label>
                        </div>
                    </div>

                </>
            )}
            {loading && (
                <div className='paymentBlur'>
                    <div className='paymentLoading'>
                        <Loading />
                    </div>
                </div>
            )}
        </div>
    );
}

function Done() {
    return (
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="140" height="140" rx="70" fill="url(#paint0_linear_46_4948)" />
            <path d="M55.2998 87.1134L36.9248 68.6836L30.7998 74.8269L55.2998 99.4L107.8 46.7433L101.675 40.6L55.2998 87.1134Z" fill="#F3F2F8" />
            <defs>
                <linearGradient id="paint0_linear_46_4948" x1="128.134" y1="6.35779" x2="11.6345" y2="133.925" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7700" />
                    <stop offset="1" stop-color="#E10CFF" />
                </linearGradient>
            </defs>
        </svg>


    );
}

function Error() {
    return (
        <svg width="140" height="141" viewBox="0 0 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="140" height="140" rx="70" fill="url(#paint0_linear_46_4973)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.6259 40.1258C40.7198 39.0323 42.2033 38.4179 43.7501 38.4179C45.2968 38.4179 46.7803 39.0323 47.8742 40.1258L70.0001 62.2517L92.1259 40.1258C92.664 39.5687 93.3077 39.1243 94.0194 38.8186C94.7311 38.5129 95.4965 38.3519 96.2711 38.3452C97.0456 38.3385 97.8137 38.4861 98.5306 38.7794C99.2475 39.0727 99.8988 39.5058 100.447 40.0535C100.994 40.6012 101.427 41.2525 101.721 41.9694C102.014 42.6863 102.162 43.4545 102.155 44.229C102.148 45.0036 101.987 45.769 101.681 46.4807C101.376 47.1924 100.931 47.8361 100.374 48.3742L78.2484 70.5L100.374 92.6258C101.437 93.726 102.025 95.1995 102.011 96.729C101.998 98.2585 101.385 99.7216 100.303 100.803C99.2216 101.885 97.7585 102.498 96.2291 102.511C94.6996 102.525 93.2261 101.937 92.1259 100.874L70.0001 78.7483L47.8742 100.874C46.774 101.937 45.3005 102.525 43.771 102.511C42.2416 102.498 40.7785 101.885 39.6969 100.803C38.6154 99.7216 38.0019 98.2585 37.9886 96.729C37.9753 95.1995 38.5633 93.726 39.6259 92.6258L61.7517 70.5L39.6259 48.3742C38.5323 47.2803 37.918 45.7968 37.918 44.25C37.918 42.7032 38.5323 41.2197 39.6259 40.1258Z" fill="#F3F2F8" />
            <defs>
                <linearGradient id="paint0_linear_46_4973" x1="70" y1="0.5" x2="70" y2="140.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#757575" />
                </linearGradient>
            </defs>
        </svg>

    );
}

function File() {
    return (
        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6663 9.41984C21.6526 9.29733 21.6257 9.17665 21.5863 9.05984V8.93984C21.5237 8.8019 21.438 8.67565 21.333 8.5665L13.333 0.566504C13.2239 0.461465 13.0976 0.375794 12.9597 0.31317H12.8397L12.413 0.166504H4.33301C3.27214 0.166504 2.25473 0.587931 1.50458 1.33808C0.754435 2.08822 0.333008 3.10564 0.333008 4.1665V22.8332C0.333008 23.894 0.754435 24.9115 1.50458 25.6616C2.25473 26.4117 3.27214 26.8332 4.33301 26.8332H17.6663C18.7272 26.8332 19.7446 26.4117 20.4948 25.6616C21.2449 24.9115 21.6663 23.894 21.6663 22.8332V9.41984ZM13.6663 4.71317L17.1197 8.1665H13.6663V4.71317ZM18.9997 22.8332C18.9997 23.1868 18.8592 23.5259 18.6092 23.776C18.3591 24.026 18.02 24.1665 17.6663 24.1665H4.33301C3.97939 24.1665 3.64025 24.026 3.3902 23.776C3.14015 23.5259 2.99967 23.1868 2.99967 22.8332V4.1665C2.99967 3.81288 3.14015 3.47374 3.3902 3.2237C3.64025 2.97365 3.97939 2.83317 4.33301 2.83317H10.9997V9.49984C10.9997 9.85346 11.1402 10.1926 11.3902 10.4426C11.6402 10.6927 11.9794 10.8332 12.333 10.8332H18.9997V22.8332Z" fill="url(#paint0_linear_44_3250)" />
            <defs>
                <linearGradient id="paint0_linear_44_3250" x1="10.9997" y1="0.166504" x2="9.65669" y2="26.7991" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F700FF" />
                    <stop offset="1" stop-color="#F3C2F9" />
                </linearGradient>
            </defs>
        </svg>

    );
}