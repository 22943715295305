import './copy.css';
import React, { useEffect, useState } from 'react';


export default function Copy({ copy, onHide, text }) {
    const [isVisible, setIsVisible] = useState(copy);

    useEffect(() => {
        if (copy) {
            setIsVisible(true);
            setTimeout(() => {
                setIsVisible(false);
                onHide();
            }, 2000);
        }
    }, [copy, onHide]);

    return (
        <div className={`copyWrapper ${isVisible ? 'show' : 'hide'}`}>
            <div className="copyBlock">
                <Sucessful />
                <p>{text}</p>
            </div>
        </div>
    );
}

function Sucessful() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
            <path fill="url(#paint_green)" d="M9 18.25a.74.74 0 0 1-.53-.25l-5-5a.75.75 0 1 1 1.06-1L9 16.44L19.47 6a.75.75 0 0 1 1.06 1l-11 11a.74.74 0 0 1-.53.25" />
            <defs>
                <linearGradient id="paint_green" x1="8" y1="0.5" x2="8" y2="15.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FE001" />
                    <stop offset="1" stop-color="#00D7BE" />
                </linearGradient>
            </defs>
        </svg>
    );
}