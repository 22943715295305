import './quickpay.css'
import React, { useState, useEffect } from 'react';
import Bank from '../Sliders/TBank/Bank';
import SBPsber from '../SBP/SBPsber';

export default function QuickPay({ cardInfo, setShowSBP, tpay, sberpay }) {


    return (
        <div className="quickPay">
            <div className="quickPayHeader">
                <h2>Быстрая оплата</h2>
                <p>Перейдите в приложение и переведите точную сумму {cardInfo.amount.toLocaleString('ru-RU')} ₽ одним платежом</p>
            </div>
            {(cardInfo.type === "SberPay" || cardInfo.type === "SBP" || cardInfo.type === "C2C" || cardInfo.type === "VTB" || cardInfo.type === "ALFA" || (cardInfo.type === "TPay" && cardInfo.card_info !== cardInfo.tpay)) ? (
                <SBPsber cardInfo={cardInfo} setShowSBP={setShowSBP} onlyReq={true} />
            ) : (
                <Bank bank={cardInfo.bank} bankFull={cardInfo.bank_full} />
            )}
            {(cardInfo.type === "VTB" || cardInfo.type === "ALFA") && (
                <p>Обратите внимание, <b>перевод будет зачислен</b>, только при отправке с <b>{cardInfo.type === "VTB" && ("ВТБ")}{cardInfo.type === "ALFA" && ("Альфа")} банка</b></p>
            )}
            {(cardInfo.type === "SberPay" || (cardInfo.type === "TPay" && cardInfo.card_info === cardInfo.tpay)) && (
                <div className="quickPayButtons">
                    {cardInfo.type === "SberPay" ? (
                        <button className='sberPay' onClick={sberpay}>Быстрый платеж <SberPay /></button>
                    ) : (
                        <button className='tPay' onClick={tpay}>Быстрый платеж <TPay /></button>
                    )}

                    <button className='SBP' onClick={() => { setShowSBP(true) }}>Оплатить через СБП <SBPico /></button>
                </div>
            )}
        </div>
    );
}

function TPay() {
    return (
        <svg width="60" height="28" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.52 0.5H7.48C3.35 0.5 0 3.86 0 8C0 12.14 3.35 15.5 7.48 15.5H28.52C32.65 15.5 36 12.14 36 8C36 3.86 32.65 0.5 28.52 0.5Z" fill="#303030"></path>
            <path d="M16.97 8.15003V6.93003H18.29C18.57 6.93003 18.73 6.96003 18.86 7.01003C19.09 7.10003 19.2 7.31003 19.2 7.54003C19.2 7.77003 19.07 8.01003 18.82 8.09003C18.7 8.13003 18.56 8.15003 18.29 8.15003H16.97ZM18.5 5.78003H15.51V10.56H16.96V9.27003H18.49C18.85 9.27003 19.2 9.27003 19.54 9.15003C20.2 8.94003 20.69 8.35003 20.69 7.55003C20.69 6.75003 20.21 6.16003 19.56 5.93003C19.2 5.80003 18.86 5.78003 18.49 5.78003H18.5Z" fill="white"></path>
            <path d="M22.42 9.87003H24.62L24.9 10.56H26.53L24.58 5.78003H22.46L20.51 10.56H22.15L22.42 9.87003ZM22.78 8.81003L23.47 6.88003H23.58L24.27 8.81003H22.78Z" fill="white"></path>
            <path d="M30.07 5.78003L28.89 7.96003H28.84L27.65 5.78003H25.96L28.11 9.29003V10.56H29.61V9.17003L31.66 5.78003H30.07Z" fill="white"></path>
            <path d="M4.34007 4.36011H12.4101V8.43011C12.4101 9.47011 11.8501 10.4401 10.9501 10.9601L8.37008 12.4501L5.79008 10.9601C4.89008 10.4401 4.33008 9.47011 4.33008 8.43011V4.36011" fill="#FFDD2D"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.56006 6.5V7.77C6.73006 7.57 7.05006 7.44 7.41006 7.44H7.80006V8.91C7.80006 9.3 7.69006 9.64 7.54006 9.83H9.24006C9.08006 9.64 8.98006 9.3 8.98006 8.91V7.44H9.37006C9.73006 7.44 10.0401 7.57 10.2201 7.77V6.5H6.58006" fill="#333333"></path>
        </svg>
    );
}

function SberPay() {

    return (
        <svg width="75" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 250.4">
            <defs>
                <linearGradient id="sberPayGradient" x1="105.37" y1="-777.13" x2="391.72" y2="-1102.01" gradientTransform="translate(0 1066.66)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#c7e701" />
                    <stop offset=".24" stop-color="#0ada01" />
                    <stop offset=".44" stop-color="#21de58" />
                    <stop offset=".63" stop-color="#3ee3a9" />
                    <stop offset=".81" stop-color="#37dabe" />
                    <stop offset="1" stop-color="#15cae0" />
                </linearGradient>
            </defs>
            <path d="m0,125.63C0,56.23,56.24,0,124.78,0h250.44c69.42,0,124.78,56.23,124.78,125.63s-56.24,124.76-124.78,124.76H125.66C56.24,251.27,0,195.04,0,125.63Z" fill='url(#sberPayGradient)' />
            <g>
                <path fill="#FFFFFF" d="m249.56,139.69v28.99h-16.7v-92.25h30.76c29,0,41.3,10.54,41.3,30.75s-14.06,32.51-41.3,32.51c0,0-14.06,0-14.06,0Zm0-48.32v33.39h15.82c14.94,0,22.85-5.27,22.85-17.57,0-11.42-7.03-15.81-22.85-15.81h-15.82Z" />
                <path fill="#FFFFFF" d="m313.71,107.18c4.39-3.51,12.3-6.15,23.73-6.15,19.33,0,29,6.15,29,23.72v43.05h-14.06v-11.42c-3.51,7.91-11.42,13.18-21.97,13.18-13.18,0-21.09-7.91-21.09-20.21,0-14.94,10.54-19.33,27.24-19.33h14.94v-2.64c0-9.66-4.39-12.3-13.18-12.3-12.3,0-19.33,4.39-23.73,11.42l-.88-19.33Zm36.91,37.78v-5.27h-13.18c-8.79,0-13.18,1.76-13.18,7.91,0,5.27,3.51,7.91,10.54,7.91,10.54,0,14.94-6.15,15.82-10.54Z" />
                <path fill="#FFFFFF" d="m373.46,101.91h16.7l17.57,44.81,14.94-44.81h15.82l-26.36,72.04c-6.15,15.81-11.42,19.33-20.21,19.33-4.39,0-8.79-.88-10.54-2.64v-14.06c1.76,1.76,5.27,3.51,7.91,3.51,4.39,0,7.91-3.51,10.54-12.3l-26.36-65.89Z" />
                <path fill="#FFFFFF" d="m98.42,107.18v20.21l26.36,16.69,64.15-46.56c-2.64-5.27-5.27-9.66-8.79-14.06l-55.36,40.41-26.36-16.69Z" />
                <path fill="#FFFFFF" d="m179.26,123.88v1.76c0,12.3-4.39,23.72-11.42,33.39s-17.57,15.81-29.88,19.33c-11.42,2.64-23.73,1.76-35.15-3.51-11.42-5.27-20.21-13.18-25.48-23.72-5.27-10.54-7.91-22.84-5.27-35.14,1.76-12.3,7.91-22.84,17.57-30.75,8.79-7.91,20.21-13.18,32.51-13.18,12.3-.88,23.73,2.64,34.27,9.66l14.06-9.66c-12.3-9.66-28.12-15.81-43.94-15.81s-31.63,5.27-43.94,14.94c-12.3,9.66-21.09,23.72-24.6,39.54-3.51,15.81-1.76,31.63,5.27,45.69s18.45,26.36,32.51,32.51c14.06,7.03,30.76,8.79,45.69,5.27,15.82-3.51,29-12.3,39.54-24.6,9.67-12.3,14.94-28.11,14.94-43.93,0-4.39,0-8.79-.88-12.3l-15.82,10.54Z" />
            </g>
        </svg>
    )
}

function SBPico() {
    return (
        <svg width="28" height="28" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_43_2094)">
                <path d="M0 8.27148L4.54124 16.4969V21.5142L0.0053125 29.7234L0 8.27148Z" fill="#5B57A2" />
                <path d="M17.4364 13.5036L21.6918 10.8607L30.4005 10.8525L17.4364 18.9003V13.5036Z" fill="#D90751" />
                <path d="M17.4126 8.2229L17.4366 19.113L12.8848 16.2789V0L17.4126 8.2229Z" fill="#FAB718" />
                <path d="M30.4006 10.8525L21.6916 10.8607L17.4126 8.2229L12.8848 0L30.4006 10.8525Z" fill="#ED6F26" />
                <path d="M17.4366 29.769V24.4854L12.8848 21.7051L12.8872 38.0001L17.4366 29.769Z" fill="#63B22F" />
                <path d="M21.6812 27.1502L4.54094 16.4969L0 8.27148L30.3821 27.1394L21.6812 27.1502Z" fill="#1487C9" />
                <path d="M12.8877 38.0004L17.4364 29.7692L21.6811 27.1504L30.382 27.1396L12.8877 38.0004Z" fill="#017F36" />
                <path d="M0.00561523 29.7228L12.9222 21.7048L8.57967 19.0049L4.54154 21.5136L0.00561523 29.7228Z" fill="#984995" />
            </g>
            <defs>
                <clipPath id="clip0_43_2094">
                    <rect width="75" height="38" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}