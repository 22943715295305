import React from "react";

export default function IBT() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#225A7F" />
            <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <circle cx="16" cy="16" r="16" fill="#225A7F" />
            </mask>
            <g mask="url(#mask0)">
                <circle cx="7.85" cy="7.15" r="2.35" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.63 11.16L0.39 12.1L6.09 12.24V28.72L8.63 36.91L14.08 37.13L24.78 30.86L24.16 29.56C24.56 25.44 22.51 22.86 18.04 21.96C25.17 19.09 23.05 10.64 14.64 10.64H9.55L0.63 11.16ZM9.54 12.31V21.43C10.41 21.97 19.43 21.98 18.92 15.8C18.41 9.63 9.54 12.31 9.54 12.31ZM9.55 22.88V34.39C10.54 35.06 21.1 35.02 20.48 27.74C19.85 20.46 9.55 22.88 9.55 22.88Z" fill="white" />
            </g>
        </svg>

    );
}